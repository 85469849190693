// React core imports
import { useMemo, useState, useEffect, useCallback } from "react"

// Third-party libraries
import dayjs, { type Dayjs } from "dayjs"
import { EncryptStorage } from "encrypt-storage"

// React Router for navigation
import { useNavigate, useSearchParams } from "react-router-dom"

// Redux Toolkit Query for API calls
import { useGetTerminalsMutation } from "../../redux/business/business.api"

// Material-UI components and hooks
import { useMediaQuery } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import Alert, { type AlertColor } from "@mui/material/Alert"

// Type imports for TypeScript
import {
  type HandleSnackBarType,
  LocationInputType,
  CalendarInputType,
} from "../../types/reportTypes"
import type { ITerminal, IBusiness } from "../../components/Terminals/TerminalsTypesFlattened"

// Custom components from your project
import { TerminalsBusinesses } from "../../components/Terminals/TerminalsBusinesses"
import { ReportLayout } from "../../layouts/ReportLayout"
import { PeriodSkeleton } from "../../layouts/Skeletons/PeriodSkeleton"

// Utilities and middleware functions
import { getReportsHandler } from "../../utils/ReportHandlerInput"
import { flattenTerminals } from "../../middlewares/flattenTerminals"

const storage = new EncryptStorage("jfkejfklrjklgjerlk485859809gjtkljkgjl")

interface Props {
  openFiltersHandler?: () => void
}

export function Terminals(props: Props) {
  const [fetchReport, error] = useGetTerminalsMutation()
  const [location, setLocation] = useState<string | null>(null)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState("")
  const [showHeaderDate, setShowHeaderDate] = useState(false)
  const hasHeaderDate = false
  const requiresAPICall = false
  const [reportDateRange, setReportDateRange] = useState<[Dayjs | null, Dayjs | null]>([
    dayjs(),
    dayjs(),
  ])
  const [headerDateRange, setHeaderDateRange] = useState<[Dayjs | null, Dayjs | null]>([null, null])
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>("error")
  const [report, setReport] = useState<IBusiness[]>([])

  const reportRecord = useMemo(() => {
    const record: Record<string, ITerminal[]> = {}
    for (const [key, value] of Object.entries(report)) {
      if (Array.isArray(value)) {
        record[key] = value
          .flatMap((terminal: ITerminal | null) => (terminal !== null ? [terminal] : []))
          .filter(Boolean)
      }
    }
    return record
  }, [report])

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"))
  const [showForm, setShowForm] = useState(isMobile)
  const [isFetchingReport, setIsFetchingReport] = useState(false)
  const [selectedValue, setSelectedValue] = useState<any>({ name: "", id: null, bussinessId: null })
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  console.log("status of mutation: ", error?.error)
  useEffect(() => {
    if (!isMobile) {
      setShowForm(true)
    }
  }, [isMobile])

  const toggleForm = useCallback(
    (show: boolean) => {
      if (!isMobile) {
        setShowForm(true)
      } else {
        setShowForm(show)
      }
    },
    [isMobile]
  )

  useEffect(() => {
    const urlLocation = searchParams.get("locationId")
    const urlName = searchParams.get("locationName")
    const urlBusinessId = searchParams.get("businessId")
    if (
      urlLocation !== null &&
      urlLocation !== undefined &&
      urlName !== null &&
      urlName !== undefined &&
      urlBusinessId !== null &&
      urlBusinessId !== undefined
    ) {
      setSelectedValue({
        businessId: urlBusinessId,
        name: urlName,
        id: urlLocation,
      })
    }
  }, [searchParams])

  const handleLocationChange = (e: React.SyntheticEvent<Element, Event>, value: number | null) => {
    setLocation(value !== null ? value.toString() : null)
  }

  const handleSetSelectedValue = (value: any | null) => {
    setSelectedValue(value)
    const newSearchParams = new URLSearchParams(searchParams.toString())
    newSearchParams.set("locationId", value.id)
    newSearchParams.set("locationName", value.name)
    newSearchParams.set("businessId", value.businessId)
    navigate(`?${newSearchParams.toString()}`, { replace: true })
  }

  const handleSnackBar: HandleSnackBarType = (isOpen, severity, message) => {
    setSnackbarSeverity(severity)
    setSnackbarMessage(message)
    setSnackbarOpen(isOpen)
  }

  useEffect(() => {
    if (selectedValue.businessId == null) return
    void (async () => {
      setIsFetchingReport(true)
      try {
        const res: any = await fetchReport({
          businessId: selectedValue.businessId,
          sortBy: "problematic",
        })
        console.log("Fetched report data:", res)
        setReport(res.data.data.terminals)
      } catch (err) {
        console.log(err)
      } finally {
        setIsFetchingReport(false)
      }
    })()
  }, [selectedValue.businessId, error?.error])

  const flattenedReport = useMemo(() => {
    const locations = storage.getItem("locations")
    return flattenTerminals({
      businesses: reportRecord,
      locations: locations ?? [],
    })
  }, [reportRecord])

  const selectedBusinessId = useMemo(() => {
    let businessId = null
    flattenedReport.forEach((business: IBusiness) => {
      const selectedStore = business?.stores?.find(
        (store: any) => store?.store?.id === selectedValue?.id
      )
      if (selectedStore !== undefined) {
        businessId = business.businessInfo.businessId
      }
    })
    return businessId
  }, [flattenedReport, selectedValue])

  return (
    <ReportLayout
      reportName={"Terminals"}
      calendarInput={CalendarInputType.NoDate}
      locationInput={LocationInputType.SelectLoad}
      showHeaderDate={showHeaderDate}
      headerDateRange={headerDateRange}
      showForm={showForm}
      accordionHeadings={[]}
      showAccordionHeadings={false}
      toggleForm={toggleForm}
      handleLocationChange={handleLocationChange}
      selectedValue={selectedValue}
      handleSetSelectedValue={handleSetSelectedValue}
      setReportDateRange={setReportDateRange}
      openFiltersHandler={props.openFiltersHandler}
      location={location}
      isActiveReport={Boolean(flattenedReport?.length ?? 0)}
      getReportHandler={async () => {
        try {
          if (location !== null) {
            await getReportsHandler(
              fetchReport,
              setIsFetchingReport,
              handleSnackBar,
              setReport,
              location,
              hasHeaderDate,
              requiresAPICall,
              setShowHeaderDate,
              "data.data.storeList",
              {
                dateString: reportDateRange[0]?.startOf("day").format("YYYY-MM-DD"),
              }
            )

            setHeaderDateRange([reportDateRange[0], reportDateRange[1]])
          } else {
            handleSnackBar(true, "error", "Please select a location first.")
          }
        } catch (err) {
          console.log(error?.error)
        }
      }}
    >
      <>
        {isFetchingReport && <PeriodSkeleton />}
        {!isFetchingReport && flattenedReport.length === 0 && snackbarOpen && (
          <Alert severity={snackbarSeverity} sx={{ width: "100%" }}>
            {snackbarMessage}
          </Alert>
        )}
        {!isFetchingReport && flattenedReport.length > 0 && (
          <TerminalsBusinesses
            selectedId={selectedValue.id}
            selectedBusinessId={selectedBusinessId}
            terminalsData={flattenedReport}
          />
        )}
      </>
    </ReportLayout>
  )
}
