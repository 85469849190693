import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import {
  FixedWidthTableCell,
  FixedWidthTableCellValue,
  FixedWidthTableCellTruncate,
  renderTypeIcon,
} from "../../../styles/AccordionStyles"
import { formatDate } from "../../../utils/formatDate"
import { formatAmount } from "../../../utils/formatAmount"

interface Props {
  visibleTransactions: any
  emptyRows: any
}

export function TransactionsTable(props: Props) {
  return (
    <TableContainer sx={{ maxHeight: 440 }}>
      <Table stickyHeader={true} size="small">
        <TableHead>
          <TableRow>
            <FixedWidthTableCell>Date</FixedWidthTableCell>
            <FixedWidthTableCell>User</FixedWidthTableCell>
            <FixedWidthTableCell>Device</FixedWidthTableCell>
            <FixedWidthTableCell>Type</FixedWidthTableCell>
            <FixedWidthTableCellValue>Amount</FixedWidthTableCellValue>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.visibleTransactions.map((transaction: any, index: number) => {
            const { dateTime, userName, device, type, amount } = transaction
            return (
              <TableRow key={index}>
                <FixedWidthTableCell>{formatDate(dateTime)}</FixedWidthTableCell>
                <FixedWidthTableCellTruncate>{userName}</FixedWidthTableCellTruncate>
                <FixedWidthTableCell>{device}</FixedWidthTableCell>
                <FixedWidthTableCell>{renderTypeIcon(type)}</FixedWidthTableCell>
                <FixedWidthTableCellValue>
                  <span className="hidden sm:block">{formatAmount(amount).full}</span>
                  <span className="block sm:hidden">{formatAmount(amount).short}</span>
                </FixedWidthTableCellValue>
              </TableRow>
            )
          })}
          {props.emptyRows > 0 &&
            Array.from({ length: props.emptyRows }, (_, i) => (
              <TableRow style={{ height: 33 }} key={`emptyRow-${i}`}>
                <TableCell colSpan={5} />
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
