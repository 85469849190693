import React from "react"

import type { FlattenedSalesReport } from "../../../types/ISalesReportTypes"
import { AccordionSalesItem } from "./AccordionSalesItem"

interface Props {
  report: FlattenedSalesReport
}

export function SalesReport({ report }: Props) {
  console.log("reprot in sales report: ", report)
  return (
    <div>
      {Object.entries(report.stores).map(([businessId, { name, stores, totalSummary }], index) => {
        const storeName = name
        const isSingleBusiness = Object.entries(report.stores).length === 1

        return (
          <React.Fragment key={`${name}${index}`}>
            <AccordionSalesItem
              isSingleBusiness={isSingleBusiness}
              businessId={businessId}
              name={storeName}
              stores={stores}
              totalSummary={totalSummary}
            />
          </React.Fragment>
        )
      })}
    </div>
  )
}
