import { IconButton, Tooltip } from "@mui/material"
import ShareIcon from "@mui/icons-material/Share"
interface ShareProps {
  shareText: string
  title: string
  fontSize?: string
  padding?: string
}
export function ShareButton({ shareText, title, fontSize = "16px", padding }: ShareProps) {
  const handleSharing = () => {
    navigator
      .share({ title, text: shareText })
      .then(() => {
        console.log("Content shared successfully")
      })
      .catch((error) => {
        console.error("Error sharing content:", error)
      })
  }

  return (
    <Tooltip placement="right" title="Share">
      <IconButton sx={{ padding }} onClick={handleSharing}>
        <ShareIcon sx={{ fontSize, color: "black" }} />
      </IconButton>
    </Tooltip>
  )
}
