import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Alert, Autocomplete, Pagination, TextField, useMediaQuery, useTheme } from "@mui/material"
import parse from "autosuggest-highlight/parse"
import match from "autosuggest-highlight/match"
import type { Option } from "../../../types/IFillCodeLookup"
import {
  useGetMachinesQuery,
  useGetFillHistoryByMachineIdQuery,
} from "../../../redux/business/business.api"
import { FillCodeLookupSkeleton } from "../../../layouts/Skeletons/FillLookupSkeleton"
import { FillLookupReport } from "../../../components/Licensing/FillCodeLookUp"
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined"
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined"

import IconButton from "@mui/material/IconButton"
import { useNavigate, useSearchParams } from "react-router-dom"

const ITEMS_PER_PAGE = 5
const allMachinesOption = { machineId: "All Machines" }

export function FillCodeLookup() {
  const theme = useTheme()
  const matchesXS = useMediaQuery(theme.breakpoints.down("sm"))
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"))
  const paginationSize = matchesXS ? "small" : "medium"
  const [page, setPage] = useState(1)
  const [selectedMachine, setSelectedMachine] = useState<Option | null>(allMachinesOption)
  const [showForm, setShowForm] = useState(isMobile)
  const {
    data: allMachines,
    error: machinesError,
    isLoading: machinesLoading,
    isFetching: machinesFetching,
  } = useGetMachinesQuery("")
  const {
    data: fillHistoryData,
    error: fillHistoryError,
    isLoading: fillHistoryLoading,
    isFetching: fillHistoryFetching,
  } = useGetFillHistoryByMachineIdQuery(
    selectedMachine?.machineId != null && selectedMachine?.machineId !== "All Machines"
      ? { machineId: selectedMachine.machineId, numberOfRecords: 0, currentPage: 0 }
      : { machineId: "", numberOfRecords: ITEMS_PER_PAGE, currentPage: page }
  )
  const machineOptions = useMemo(() => {
    return [allMachinesOption, ...(allMachines?.data?.machines ?? [])]
  }, [allMachines])

  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const fillHistory = fillHistoryData?.data?.fillHistory ?? []
  const newSearchParams = new URLSearchParams(searchParams.toString())

  const handleChange = async (value: number) => {
    newSearchParams.set("page", value.toString())
    navigate(`?${newSearchParams.toString()}`, { replace: false })
    setPage(value)
    window.scrollTo({ top: 0 })
  }

  const handleMachineChange = (event: React.SyntheticEvent, newValue: Option | null) => {
    setSelectedMachine(newValue)
    setPage(1)
  }

  const toggleForm = useCallback(
    (show: boolean) => {
      if (!isMobile) {
        setShowForm(true)
      } else {
        setShowForm(show)
      }
    },
    [isMobile]
  )

  useEffect(() => {
    const urlPage = searchParams.get("page")
    const urlMachine = searchParams.get("machine")
    if (urlPage === null) {
      setPage(1)
    }

    if (urlPage !== null) {
      setPage(+urlPage)
    }

    if (urlMachine !== null && allMachines !== null) {
      setSelectedMachine({ machineId: urlMachine })
    } else {
      if (!machinesLoading && allMachines !== null) {
        setSelectedMachine(allMachinesOption)
      }
    }
  }, [searchParams, allMachines])

  useEffect(() => {
    newSearchParams.set("page", page.toString())
    if (selectedMachine !== null && selectedMachine.machineId !== "All Machines") {
      setPage(1)
      newSearchParams.set("machine", selectedMachine.machineId)
    }
  }, [page, selectedMachine, navigate])

  useEffect(() => {
    if (machinesError !== undefined && machinesError !== null) {
      console.log(machinesError)
    }
    if (fillHistoryError !== undefined && fillHistoryError !== null) {
      console.log(fillHistoryError)
    }
  }, [machinesError, fillHistoryError])

  useEffect(() => {
    if (!isMobile) {
      setShowForm(true)
    }
  }, [isMobile])

  useEffect(() => {
    if (
      isMobile &&
      fillHistoryData != null &&
      fillHistory != null &&
      Object.keys(fillHistory).length !== 0
    ) {
      toggleForm(false)
    }
  }, [fillHistoryData, isMobile, fillHistory, toggleForm])

  console.log("showForm", showForm)
  return (
    <div className={`w-full sm:max-w-4xl mx-auto mb-20`}>
      <div className="flex justify-between items-center py-1 px-2 bg-[#14213D]">
        {/*  added padding since we don't have FullscreenToggle anymore but we might add it later */}
        <div className="flex pl-8">{/* <FullscreenToggle /> */}</div>
        <div className="flex-grow text-center">
          <h1
            className="text-2xl font-bold text-white"
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" })
            }}
          >
            Fill Code Lookup
          </h1>
        </div>
        <div className="flex">
          <div style={{ width: "40px", height: "40px" }}>
            {isMobile &&
              (showForm ? (
                <IconButton
                  onClick={() => {
                    toggleForm(!showForm)
                  }}
                  sx={{ color: "#fff" }}
                >
                  <ExpandLessOutlinedIcon />
                </IconButton>
              ) : (
                <IconButton
                  onClick={() => {
                    toggleForm(!showForm)
                  }}
                  sx={{ color: "#fff" }}
                >
                  <ExpandMoreOutlinedIcon />
                </IconButton>
              ))}
          </div>
        </div>
      </div>

      {showForm && (
        <div
          className={`${
            showForm ? "max-h-[1000px] opacity-100" : "max-h-0 opacity-0"
          } overflow-hidden transition-all duration-500`}
        >
          <form className="px-4 pt-2 pb-5 mt-3 w-full">
            <div className="flex gap-6 justify-between flex-col sm:flex-row">
              <Autocomplete
                isOptionEqualToValue={(option, value) => option.machineId === value.machineId}
                disabled={machinesLoading || fillHistoryLoading}
                disableClearable={true}
                className="my-4 xs:w-full sm:w-80 xs:px-0 "
                value={selectedMachine}
                blurOnSelect={true}
                options={machineOptions}
                getOptionLabel={(option) => option.machineId}
                onChange={handleMachineChange}
                renderInput={(params) => <TextField {...params} label="Choose a Machine" />}
                renderOption={(props, option, { inputValue }) => {
                  const matches = match(option.machineId, inputValue, {
                    insideWords: true,
                  })
                  const parts = parse(option.machineId, matches)

                  return (
                    <li {...props} key={option.machineId}>
                      <div>
                        {parts.map((part, index) => (
                          <span
                            key={index}
                            style={{
                              fontWeight: part.highlight ? 700 : 400,
                            }}
                          >
                            {part.text}
                          </span>
                        ))}
                      </div>
                    </li>
                  )
                }}
              />
            </div>
          </form>
        </div>
      )}

      {machinesFetching || fillHistoryFetching ? (
        <FillCodeLookupSkeleton numCards={selectedMachine?.machineId === "All Machines" ? 5 : 1} />
      ) : (
        <>
          {fillHistoryData?.data?.total === 0 ? (
            <Alert severity="error" sx={{ width: "100%" }}>
              No data to show with the current selection.
            </Alert>
          ) : (
            <>
              <div className="flex flex-col">
                {selectedMachine != null && <FillLookupReport report={fillHistory} />}
              </div>
              {selectedMachine?.machineId === "All Machines" &&
                allMachines?.data?.machines?.length !== 0 &&
                fillHistoryData?.data?.total !== 0 && (
                  <div className="flex my-8 justify-center">
                    <Pagination
                      size={paginationSize}
                      count={Math.ceil(fillHistoryData?.data?.total / ITEMS_PER_PAGE)}
                      page={page}
                      boundaryCount={1}
                      siblingCount={1}
                      onChange={(event, value) => {
                        handleChange(value).catch((error) => {
                          console.error("Error during pagination:", error)
                        })
                      }}
                      sx={{
                        "& .MuiPagination-ul": {
                          flexWrap: "nowrap",
                        },
                        "& .MuiPaginationItem-root": {
                          "& .MuiTouchRipple-root": {
                            display: "none",
                          },
                          "&:hover": {
                            backgroundColor: matchesXS ? "transparent" : "undefined",
                          },
                        },
                      }}
                    />
                  </div>
                )}
            </>
          )}
        </>
      )}
    </div>
  )
}

export default FillCodeLookup
