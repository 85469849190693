import { memo, useRef, useState } from "react"
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import ReactToPrint from "react-to-print"
import PrintIcon from "@mui/icons-material/Print"
import { ShareButton } from "../../ShareButton"
import type { Machine, Transaction } from "../../../types/IFillHistory"
import { formatAmount } from "../../../utils/formatAmount"
import { formatDate } from "../../../utils/formatDate"
import Print from "../Print"
import { formatFillCode } from "../../../utils/formatFillCode"
import { PurchaseHistoryTypographyStyled } from "../../../styles/PurchaseHistoryTypographyStyled"
import { CopyButton } from "../../CopyButton"
import { PurchaseMethodIcon } from "../../../styles/PurchaseMethodIcon"
import { PurchaseTimeIcon } from "../PurchaseTimeIcon"
import { type PrintDataTypes } from "../../../types/IPrint"

const PurchaseReportItemComponent = ({
  transactionId,
  purchaseDate,
  purchaseMethod,
  purchaseTotal,
  machines,
}: Transaction) => {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(700))
  const [printData, setPrintData] = useState<PrintDataTypes>()
  const printComponentRef = useRef<HTMLElement>(null)

  const getTransactionShareText = (machines: Machine[] = []) => {
    const parts = [
      `Transaction ${String(transactionId)}`,
      `Purchase Date: ${String(formatDate(purchaseDate))}`,
      `Total: ${String(formatAmount(purchaseTotal).full)}`,
    ]

    machines.forEach((machine) => {
      parts.push(
        `Machine ${String(machine.machineId)}:`,
        `Fill Code: ${formatFillCode(machine.fillCode)}`,
        `Sequence: ${String(machine.fillCount)}`,
        `Amount: ${formatAmount(machine.amount).full}`,
        `Price: ${formatAmount(machine.price).full}`,
        `\n`
      )
    })

    return parts.join("\n")
  }

  const getMachineShareText = (machines: Machine[] = []) => {
    const parts = [
      `Transaction ${String(transactionId)}`,
      `Purchase Date: ${String(formatDate(purchaseDate))}`,
    ]

    machines.forEach((machine) => {
      parts.push(
        `Machine ${String(machine.machineId)}:`,
        `Fill Code: ${formatFillCode(machine.fillCode)}`,
        `Sequence: ${String(machine.fillCount)}`,
        `Amount: ${formatAmount(machine.amount).full}`,
        `Price: ${formatAmount(machine.price).full}`,
        ``
      )
    })

    return parts.join("\n")
  }

  const prepareDataForPrint = () => {
    // Top-level details
    const transactionData = [
      {
        key: `transactionId-${String(transactionId)}`,
        label: "Transaction ID",
        value: transactionId,
      },
      {
        key: `purchaseTotal-${String(transactionId)}`,
        label: "Purchase Total",
        value: formatAmount(purchaseTotal).full,
      },
      {
        key: `purchaseDate-${String(transactionId)}`,
        label: "Purchase Date",
        value: formatDate(purchaseDate),
      },
      {
        key: `divider`,
        label: "",
        value: "",
      },
    ]

    // Second-level details
    const machineData = machines.flatMap((machine) => [
      {
        key: `machineId-${String(machine.machineId)}`,
        label: "Machine ID",
        value: machine.machineId,
      },
      {
        key: `fillCode-${String(machine.machineId)}`,
        label: "Fill Code",
        value: formatFillCode(machine.fillCode),
      },
      {
        key: `count-${String(machine.machineId)}`,
        label: "Sequence",
        value: `${String(machine.fillCount)}`,
      },
      {
        key: `amount-${String(machine.machineId)}`,
        label: "Amount",
        value: formatAmount(machine.amount).full,
      },
      {
        key: `price-${String(machine.machineId)}`,
        label: "Price",
        value: formatAmount(machine.price).full,
      },
      {
        key: `divider`,
        label: "",
        value: "",
      },
    ])
    return [...transactionData, ...machineData]
  }

  return (
    <div>
      {machines?.map((machine, mIndex) => (
        <Card
          sx={{
            borderRadius: 0,
            borderTop: "1px solid #a9a9a9",
          }}
          variant="outlined"
          key={transactionId}
        >
          <CardHeader
            sx={{
              padding: isSmallScreen ? 1 : 2,
              backgroundColor: "#DEE2E6",
              "& .MuiCardHeader-action": {
                margin: "1px",
              },
            }}
            title={transactionId}
            subheader={
              <>
                <Typography sx={{ display: "flex", alignItems: "center" }} variant="body2">
                  {" "}
                  <PurchaseMethodIcon purchaseMethod={purchaseMethod} />
                  {purchaseMethod?.toLowerCase() === "invoice" ? "Invoiced: " : "Paid: "}
                  {formatAmount(purchaseTotal).full}
                </Typography>

                <Typography
                  variant="body2"
                  sx={{
                    display: "flex",
                    alignContent: "center",
                    alignItems: "center",
                  }}
                  color="textSecondary"
                >
                  <PurchaseTimeIcon purchaseMethod={purchaseMethod} />
                  {formatDate(purchaseDate)}
                </Typography>
              </>
            }
            action={
              <Stack direction="column" spacing={1} sx={{ minHeight: "70px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "baseline",
                  }}
                >
                  <ReactToPrint
                    trigger={() => (
                      <Tooltip title="Print">
                        <IconButton>
                          <PrintIcon sx={{ fontSize: "20px", color: "black" }} />
                        </IconButton>
                      </Tooltip>
                    )}
                    content={() => printComponentRef.current}
                    removeAfterPrint={false}
                    onBeforeGetContent={async () => {
                      const preparedData = prepareDataForPrint()
                      setPrintData(preparedData)
                    }}
                  />

                  <ShareButton
                    fontSize="20px"
                    shareText={getTransactionShareText(machines)}
                    title="Transaction Details"
                  />
                </div>
              </Stack>
            }
          />
          <CardContent sx={{ padding: 0 }}>
            <Grid
              sx={{
                backgroundColor: "#F8F9FA",
                paddingY: 1,
                marginLeft: 0,
                paddingX: isSmallScreen ? 1 : 2,
                display: "grid",
              }}
              container
              gridTemplateColumns={
                isSmallScreen ? "2fr 4fr 2fr 3fr 1fr" : "2fr 3fr 1fr 2fr 2fr 2fr"
              }
              columnSpacing={1}
            >
              <Grid item>
                <Typography sx={{ whiteSpace: "nowrap" }} variant="subtitle2">
                  {isSmallScreen ? "M. ID" : "Machine ID"}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle2">Fill Code</Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle2" align="right">
                  Seq.
                </Typography>
              </Grid>
              {!isSmallScreen && (
                <Grid item>
                  <Typography variant="subtitle2" align="right">
                    Amount
                  </Typography>
                </Grid>
              )}
              <Grid item>
                <Typography variant="subtitle2" align="right">
                  Price
                </Typography>
              </Grid>

              <Grid item></Grid>
            </Grid>

            <Grid
              key={machine?.machineId}
              container
              gridTemplateColumns={
                isSmallScreen ? "2fr 4fr 2fr 3fr 1fr" : "2fr 3fr 1fr 2fr 2fr 2fr"
              }
              columnSpacing={1}
              sx={{
                marginLeft: 0,
                paddingY: 1,
                paddingX: isSmallScreen ? 1 : 2,
                display: "grid",
                alignItems: "start",
              }}
            >
              <Grid item>
                {isSmallScreen ? (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <PurchaseHistoryTypographyStyled>
                      {machine?.machineId.split("-")[0]}-
                    </PurchaseHistoryTypographyStyled>
                    <PurchaseHistoryTypographyStyled>
                      {machine?.machineId.split("-")[1]}
                    </PurchaseHistoryTypographyStyled>
                  </div>
                ) : (
                  <PurchaseHistoryTypographyStyled>
                    {machine?.machineId}
                  </PurchaseHistoryTypographyStyled>
                )}
              </Grid>
              <Grid item>
                <Typography
                  sx={{
                    letterSpacing: "1px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                  variant="body2"
                >
                  {formatFillCode(machine?.fillCode)}
                </Typography>
              </Grid>
              <Grid item>
                <PurchaseHistoryTypographyStyled align="right">
                  {machine?.fillCount}
                </PurchaseHistoryTypographyStyled>
              </Grid>
              {!isSmallScreen && (
                <Grid item>
                  <PurchaseHistoryTypographyStyled align="right">
                    {formatAmount(machine?.amount).full}
                  </PurchaseHistoryTypographyStyled>
                </Grid>
              )}
              <Grid item>
                <PurchaseHistoryTypographyStyled align="right">
                  {formatAmount(machine?.price).full}
                </PurchaseHistoryTypographyStyled>
              </Grid>
              <Grid
                item
                sx={{
                  display: "flex",
                  minHeight: "100%",
                  flexDirection: isSmallScreen ? "column-reverse" : "row",
                  justifyContent: isSmallScreen ? "space-evenly" : "flex-end",
                  alignItems: isSmallScreen ? "center" : "baseline",
                  gap: 1,
                }}
              >
                <CopyButton copyText={machine?.fillCode} />
                <ShareButton
                  shareText={getMachineShareText(machines)}
                  title="Machine Details"
                  padding="0px"
                />
              </Grid>
            </Grid>
          </CardContent>
          <div style={{ display: "none" }}>
            {printData !== undefined && <Print ref={printComponentRef} printData={printData} />}
          </div>
        </Card>
      ))}
    </div>
  )
}
export const PurchaseReportItem = memo(PurchaseReportItemComponent)
