import Skeleton from "@mui/material/Skeleton"

interface Props {
  reportName: string
}

export function GetOverviewSkeleton({ reportName }: Props) {
  return (
    <div className="w-full sm:max-w-4xl mx-auto mb-20">
      <div className="shadow-top shadow-bottom:hidden border-b border-gray-300 sticky top-0 z-50 bg-white">
        <div className="flex justify-between items-center py-1 px-2 bg-[#14213D]">
          <div className="flex pl-8"></div>
          <div className="flex-grow text-center">
            <h1 className="text-2xl font-bold text-white">{reportName}</h1>
          </div>
          <div className="flex">
            <div style={{ width: "40px", height: "40px" }} />
          </div>
        </div>
        <Skeleton variant="rectangular" height={50} style={{ marginBottom: "16px" }} />
      </div>

      <div style={{ width: "100%", padding: "0px", boxSizing: "border-box" }}>
        <Skeleton variant="rectangular" height={150} style={{ marginBottom: "16px" }} />
        <Skeleton variant="rectangular" height={150} style={{ marginBottom: "10px" }} />
        <Skeleton variant="rectangular" height={150} style={{ marginBottom: "16px" }} />
        <Skeleton variant="rectangular" height={150} style={{ marginBottom: "10px" }} />
      </div>
    </div>
  )
}
