import React, { useRef, useState } from "react"
import ZoomInIcon from "@mui/icons-material/ZoomIn"
import IconButton from "@mui/material/IconButton"
import Button from "@mui/material/Button"
import type { MachineData, PurchaseDetailsProps } from "../../../types/paymentStatusTypes"
import { useNavigate } from "react-router-dom"
import ReactToPrint from "react-to-print"
import toast from "react-hot-toast"
import { formatAmount } from "../../../utils/formatAmount"
import { formatDate } from "../../../utils/formatDate"
import { Dialog, DialogContent, DialogTitle, Stack, Tooltip } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import PrintIcon from "@mui/icons-material/Print"
import { formatFillCode } from "../../../utils/formatFillCode"
import { ShareButton } from "../../ShareButton"
import type { PrintDataTypes } from "../../../types/IPrint"
import Print from "../Print"
import { CopyButton } from "../../CopyButton"
// import { Divider } from "@mui/material"

export function PurchaseDetails({
  transactionId,
  purchaseDate,
  purchaseTotal,
  machineData,
}: PurchaseDetailsProps) {
  const [openDialog, setOpenDialog] = useState(false)
  const [selectedMachine, setSelectedMachine] = useState<MachineData | null>(null)
  const navigate = useNavigate()
  const [printData, setPrintData] = useState<PrintDataTypes>()
  const printComponentRef = useRef<HTMLElement>(null)
  const handleOpenDialog = () => {
    setOpenDialog(true)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  const handleOkClick = () => {
    toast.dismiss()
    toast.success("Thanks, transaction completed successfully.")
    navigate("/licensing")
  }

  const getTransactionShareText = () => {
    const parts = [
      `Transaction ${String(transactionId)}`,
      `Purchase Date: ${String(formatDate(purchaseDate))}`,
      `Total: ${String(formatAmount(+purchaseTotal).full)}`,
      `\n`,
    ]
    console.log("machineData in PurchaseDetails: ", machineData)
    const machineParts = machineData
      .flatMap((machine) => [
        `Machine ${String(machine.machineId)}:`,
        `Fill Code: ${String(machine.fillCode)}`,
        `Price: ${formatAmount(machine.price).full}`,
        ``,
      ])
      .flat() // Flatten the array of arrays into a single array

    return parts.concat(machineParts).join("\n")
  }

  const prepareDataForPrint = (): PrintDataTypes => {
    // Top-level details
    const transactionData = [
      {
        key: `transactionId-${String(transactionId)}`,
        label: "Transaction ID",
        value: transactionId,
      },
      {
        key: `purchaseTotal-${String(transactionId)}`,
        label: "Purchase Total",
        value: formatAmount(+purchaseTotal).full,
      },
      {
        key: `purchaseDate-${String(transactionId)}`,
        label: "Purchase Date",
        value: formatDate(purchaseDate),
      },
      {
        key: `divider`,
        label: "",
        value: "",
      },
    ]

    // Second-level details
    const machinePrintData = machineData.flatMap((machine) => [
      {
        key: `machineId-${String(machine.machineId)}`,
        label: "Machine ID",
        value: machine.machineId,
      },
      {
        key: `price-${String(machine.machineId)}`,
        label: "Price",
        value: formatAmount(machine.price).full,
      },
      {
        key: `fillCode-${String(machine.machineId)}`,
        label: "Fill Code",
        value: formatFillCode(machine.fillCode),
      },
      {
        key: `divider`,
        label: "",
        value: "",
      },
    ])
    return [...transactionData, ...machinePrintData]
  }

  return (
    <div className="bg-white">
      <h1 className="text-lg font-bold text-center pt-2">Thank you for your purchase</h1>
      <div className="shadow-md border-b border-gray-300 flex justify-between">
        <div className="leading-8 font-bold px-2 pb-2">
          <h4>
            Transaction ID: <span className="font-normal">{transactionId} </span>
          </h4>
          <h4>
            Purchase Date: <span className="font-normal">{formatDate(purchaseDate)}</span>
          </h4>
          <h4>
            Purchase Total: <span className="font-normal">{formatAmount(+purchaseTotal).full}</span>
          </h4>
        </div>
        <Stack direction="column" spacing={1} sx={{ minHeight: "70px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              alignItems: "flex-end",
            }}
          >
            <ReactToPrint
              trigger={() => (
                <Tooltip title="Print">
                  <IconButton>
                    <PrintIcon sx={{ fontSize: "20px", color: "black" }} />
                  </IconButton>
                </Tooltip>
              )}
              content={() => printComponentRef.current}
              removeAfterPrint={false}
              onBeforeGetContent={async () => {
                const preparedData = prepareDataForPrint()
                setPrintData(preparedData)
              }}
            />

            <ShareButton
              fontSize="20px"
              shareText={getTransactionShareText()}
              title="Transaction Details"
            />
          </div>
        </Stack>
      </div>
      <div className="max-h-[350px] sm:max-h-[400] overflow-auto">
        <div>
          <div style={{ position: "sticky", top: 0, zIndex: 1 }}>
            <div className="grid grid-cols-[25%_35%_15%_auto] gap-3 bg-[#DEE2E6] px-2">
              <h3 className="text-md font-bold py-2">Machine ID</h3>
              <h3 className="text-md font-bold py-2">Fill Code</h3>
              <h3 className="text-md font-bold text-right py-2">Price</h3>
            </div>
          </div>
          {machineData.map((machine, index) => (
            <>
              <div
                key={index}
                className="grid grid-cols-[25%_35%_15%_auto] gap-3 border-b border-gray-300 px-2"
              >
                <div className="py-[7px]">{machine.machineId}</div>
                <div className="font-bold  text-sm py-[7px]  tracking-wider">
                  {formatFillCode(machine.code)}
                </div>
                <div className=" text-right py-[7px]">{formatAmount(+machine.price).full}</div>
                <div className="flex justify-end">
                  <div>
                    <IconButton
                      sx={{ alignItems: "baseline", padding: "5px" }}
                      onClick={() => {
                        setSelectedMachine(machine)
                        handleOpenDialog()
                      }}
                    >
                      <ZoomInIcon />
                    </IconButton>
                  </div>
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
      <div className="fixed bottom-20 shadow-top bg-white w-full z-50 pt-5 px-2 flex justify-end sm:static sm:pb-3 sm:shadow-none">
        <Button
          onClick={handleOkClick}
          variant="contained"
          color="primary"
          className="w-full max-w-[120px]"
        >
          Ok
        </Button>
      </div>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <div className="border-[3px] border-black">
          <DialogTitle sx={{ fontWeight: "700", textAlign: "center" }}>
            {selectedMachine?.machineId}
            <hr className="mt-3 border-[1px] border-black rounded-md" />
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            style={{ position: "absolute", right: "8px", top: "16px" }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent sx={{ paddingTop: 0 }}>
            {selectedMachine !== null && selectedMachine !== undefined && (
              <div className="text-center text-2xl">
                <h4 className="font-bold text-3xl py-4 ">
                  {formatFillCode(selectedMachine.code)}{" "}
                </h4>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CopyButton copyText={selectedMachine.code} fontSize="24px" />
                </div>
              </div>
            )}
          </DialogContent>
        </div>
      </Dialog>
      <div style={{ display: "none" }}>
        {printData !== undefined && <Print ref={printComponentRef} printData={printData} />}
      </div>
    </div>
  )
}
