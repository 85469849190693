import React, { useState, useEffect } from "react"
import { useTheme } from "@mui/material/styles"
import Pagination from "@mui/material/Pagination"
import Typography from "@mui/material/Typography"
import useMediaQuery from "@mui/material/useMediaQuery"
import { BusinessCard } from "./OverviewBusinessCard"
import { CardContentStyled, CardStyled } from "../../styles/OverviewCardStyles"
import type { Business, OverviewData } from "./OverviewTypesFlattened"
import { useLocation, useNavigate } from "react-router-dom"

interface OverviewBusinessesProps {
  overView: OverviewData
}

const ITEMS_PER_PAGE = 10

export function OverviewBusinesses({ overView }: OverviewBusinessesProps): React.ReactElement {
  const theme = useTheme()
  const matchesXS = useMediaQuery(theme.breakpoints.down("sm"))

  const variant = matchesXS ? "body2" : "h6"
  const paginationSize = matchesXS ? "small" : "medium"
  const location = useLocation()
  const navigate = useNavigate()

  const query = new URLSearchParams(location.search)
  const pageFromUrl = parseInt(query.get("page") ?? "1", 10)

  const [page, setPage] = useState(pageFromUrl)

  // Update the page state when the URL changes
  useEffect(() => {
    const currentPage = parseInt(query.get("page") ?? "1", 10)
    setPage(currentPage)
  }, [location.search])

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    if (value !== page) {
      navigate(`?page=${value}`, { replace: false })
      setPage(value)
      window.scrollTo({ top: 0 })
    }
  }

  // Calculate the start and end index for slicing the businesses array
  const startIndex = (page - 1) * ITEMS_PER_PAGE
  const endIndex = startIndex + ITEMS_PER_PAGE

  return (
    <div>
      <CardStyled>
        <CardContentStyled>
          <div className="flex mx-4 my-2 justify-between">
            <Typography variant={variant} noWrap>
              Businesses: {overView.businessCount}
            </Typography>
            <Typography variant={variant} noWrap>
              Locations: {overView.locationCount}
            </Typography>
            <Typography variant={variant} noWrap>
              Terminals: {overView.terminalCount}
            </Typography>
          </div>
        </CardContentStyled>
      </CardStyled>
      {overView.businesses.slice(startIndex, endIndex).map((business: Business) => (
        <React.Fragment key={business.businessInfo.businessId}>
          <BusinessCard business={business} />
        </React.Fragment>
      ))}
      <div className="flex my-8 justify-center">
        <Pagination
          size={paginationSize}
          count={Math.ceil(overView.businessCount / ITEMS_PER_PAGE)}
          page={page}
          boundaryCount={1}
          siblingCount={1}
          onChange={handleChange}
          sx={{
            "& .MuiPagination-ul": {
              flexWrap: "nowrap",
            },
            "& .MuiPaginationItem-root": {
              "& .MuiTouchRipple-root": {
                display: "none",
              },
              "&:hover": {
                backgroundColor: matchesXS ? "transparent" : "undefined",
              },
            },
          }}
        />
      </div>
    </div>
  )
}
