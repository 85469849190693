import { IconButton, Tooltip } from "@mui/material"
import CopyAllIcon from "@mui/icons-material/CopyAll"
import copy from "copy-to-clipboard"
import toast from "react-hot-toast"

interface Props {
  copyText: string
  fontSize?: string
}

export function CopyButton({ copyText, fontSize = "16px" }: Props) {
  const handleCopyClick = (fillCode: string) => () => {
    copy(fillCode)
    toast.dismiss()
    toast.success("Copied!")
  }

  return (
    <Tooltip placement="left" title="Copy Fill Code">
      <IconButton
        sx={{
          display: "flex",
          alignItems: "baseline",
          padding: 0,
        }}
        onClick={handleCopyClick(copyText)}
      >
        <CopyAllIcon sx={{ fontSize, color: "black" }} />
      </IconButton>
    </Tooltip>
  )
}
