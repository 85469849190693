import { RoutesContainer } from "./routes/RoutesContainer"
import toast, { Toaster } from "react-hot-toast"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import { useVerifyTokenQuery } from "./redux/user/user.api"
import { useEffect, useState } from "react"
import { CircularProgress } from "@mui/material"
import { useLocation, useNavigate } from "react-router-dom"
import ReactGA from "react-ga"
import OfflineBackdrop from "./components/OfflineBackDrop"
import { setNavigateFunction } from "./services/NavigationService"

// Initialize Google Analytics
ReactGA.initialize(process.env.REACT_APP_GTAG_ID ?? "")

export const App = () => {
  const { error, data, isLoading: isTokenLoading } = useVerifyTokenQuery("")
  const [isOffline, setIsOffline] = useState(!navigator.onLine)

  const location = useLocation()
  const navigate = useNavigate()

  const token = localStorage.getItem("token")
  const username = localStorage.getItem("name") ?? ""

  const urlParams = new URLSearchParams(location.search)
  const cParam = urlParams.get("c")

  const isAuthenticated = token !== null && token !== undefined && token !== ""

  const saveCParamAndRedirect = (cParam: string) => {
    sessionStorage.setItem("cParam", cParam)
    navigate(`/login?c=${cParam}`)
  }

  console.log("token data", data)

  const customTheme = createTheme({
    palette: {
      primary: {
        main: "#14213D",
        contrastText: "#ffffff",
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          containedPrimary: {
            "@media (hover: hover)": {
              "&:hover": {
                backgroundColor: "#FCA311",
              },
            },
            "&:active": {
              backgroundColor: "#FCA311",
            },
          },
        },
      },
    },
  })

  useEffect(() => {
    // Handle the "c" parameter logic
    if (cParam !== null && cParam !== undefined) {
      if (isAuthenticated) {
        // Redirect to purchase-fills page
        navigate(`/licensing/purchase-fills?c=${cParam}`)
      } else {
        // Save the "c" parameter and redirect to login
        saveCParamAndRedirect(cParam)
      }
    } else if (!isAuthenticated) {
      // Check for a saved "c" parameter and redirect to login
      const savedCParam = sessionStorage.getItem("cParam")
      if (savedCParam !== null && savedCParam !== undefined) {
        saveCParamAndRedirect(savedCParam)
      }
    }
  }, [cParam, isAuthenticated, navigate])

  // Track pageviews
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search)
    ReactGA.event({
      category: "User Authentication",
      action: "login",
      label: username,
    })
  }, [location])

  useEffect(() => {
    if (token !== null && token !== undefined && token !== "") {
      if (location.pathname === "/login") {
        navigate("/")
      }
    }
  }, [location, navigate])

  useEffect(() => {
    if (!isTokenLoading && (error as any)?.status === 401) {
      // localStorage.clear()
      localStorage.removeItem("token")
      localStorage.removeItem("name")
      localStorage.removeItem("locations")
      navigate("/login")
    }
  }, [isTokenLoading, error])

  // scroll to the top automatically on every  page
  useEffect(() => {
    window.scrollTo(0, 0)

    return () => {
      // Cleanup logic to dismiss toast when the component unmounts or location changes
      toast.dismiss("loadingToast")
    }
  }, [location.pathname])

  useEffect(() => {
    const handleOnline = () => {
      setIsOffline(false)
    }
    const handleOffline = () => {
      setIsOffline(true)
    }

    window.addEventListener("online", handleOnline)
    window.addEventListener("offline", handleOffline)

    return () => {
      window.removeEventListener("online", handleOnline)
      window.removeEventListener("offline", handleOffline)
    }
  }, [isOffline])

  // we need this useEffect to initialize our navigation service for error boundary since we can't use hooks outside the component cycle
  useEffect(() => {
    setNavigateFunction(navigate)
  }, [navigate])

  if (isTokenLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <CircularProgress color="primary" size={50} />
      </div>
    )
  }

  return (
    <ThemeProvider theme={customTheme}>
      <OfflineBackdrop isOffline={isOffline} />
      {/* <ScrollTop /> */}
      <RoutesContainer />
      <Toaster />
    </ThemeProvider>
  )
}
