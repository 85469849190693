import Typography from "@mui/material/Typography"

interface SelectableTypographyProps {
  selected: boolean
  children: React.ReactNode
}

export const SelectableTypography = ({ selected, children }: SelectableTypographyProps) => (
  <Typography
    sx={{
      fontWeight: selected ? "bold" : "normal",
      fontSize: { xs: "0.8rem", sm: "1rem" },
    }}
  >
    {children}
  </Typography>
)
