import { type RouteObject, useRoutes } from "react-router-dom"
import {
  Home,
  Overview,
  Terminals,
  Login,
  Reports,
  CashAdjustments,
  Performance,
  Error,
  Revenue,
  Period,
  Sales,
  Collections,
  CurrentCash,
  // // part of disable Support
  // Support,
  AccountSettings,
  BusinessDetails,
  TerminalDetailsPage,
  Licensing,
  PurchaseFills,
  PurchaseHistory,
  FillCodeLookup,
} from "../pages"
import NotFound from "../pages/NotFound"
import { AuthLayout } from "../layouts/AuthLayout"
import { SidebarLayout } from "../layouts/SidebarLayout"
import { FiltersLayout } from "../layouts/FiltersLayout"
import { type ComponentType } from "react"
import { PaymentStatus } from "../pages/Licensing/PaymentStatus"
import { OrderSummary } from "../pages/Licensing/OrderSummary/Index"

interface ComponentProps {
  openFiltersHandler?: () => void
}

const layoutRouter = (
  Component: ComponentType<ComponentProps>,
  path: string,
  hasFilters = false
): RouteObject => {
  return {
    path,
    element: <AuthLayout />,
    children: [
      {
        path: "",
        element: (
          <SidebarLayout>
            {hasFilters ? <FiltersLayout Component={Component} /> : <Component />}
          </SidebarLayout>
        ),
      },
    ],
  }
}

export function RoutesContainer() {
  const element = useRoutes([
    {
      path: "/login",
      element: <Login />,
    },

    {
      path: "/",
      element: <AuthLayout />,
      children: [
        {
          path: "",
          element: <Home />,
        },
      ],
    },

    layoutRouter(Reports, "/reports"),

    layoutRouter(Overview, "/overview"),

    layoutRouter(BusinessDetails, "/business-details"),

    layoutRouter(TerminalDetailsPage, "/terminal-details"),

    layoutRouter(Terminals, "/terminals", true),

    layoutRouter(Licensing, "/licensing"),

    layoutRouter(PurchaseFills, "/licensing/purchase-fills"),

    layoutRouter(OrderSummary, "/licensing/order-summary"),

    layoutRouter(PurchaseHistory, "/licensing/purchase-history"),

    layoutRouter(FillCodeLookup, "/licensing/fill-lookup"),

    layoutRouter(PaymentStatus, "/licensing/payment-status"),

    layoutRouter(CashAdjustments, "/reports/cash-adjustments", true),

    layoutRouter(Performance, "/reports/performance"),

    layoutRouter(Revenue, "/reports/revenue", true),

    layoutRouter(Period, "/reports/period"),

    layoutRouter(Sales, "/reports/sales", true),

    layoutRouter(Collections, "/reports/collections", true),

    layoutRouter(CurrentCash, "/reports/current-cash", true),

    // // part of disable Support
    // layoutRouter(Support, "/support"),

    layoutRouter(AccountSettings, "/settings"),

    // Catch-all route
    {
      path: "/error",
      element: <Error />,
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ])

  return element
}
