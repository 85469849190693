import toast from "react-hot-toast"
import type { HandleErrorType } from "../types/reportTypes"
import { navigateTo } from "../services/NavigationService"

const handle401Error: HandleErrorType = () => {
  // localStorage.clear()
  localStorage.removeItem("token")
  localStorage.removeItem("name")
  localStorage.removeItem("locations")
  navigateTo("/login")
  toast.dismiss()
  toast.error("Session has expired, please login again.")
}

const handle400Error: HandleErrorType = () => {
  toast.dismiss()
  toast.error("An error occurred while fetching the report.")
}

const handle524Error: HandleErrorType = () => {
  if (!navigator.onLine) {
    toast.dismiss()
    toast.error("You are offline. Please check your internet connection and try again.")
  } else {
    toast.dismiss()
    toast.error("Server Timeout")
  }
}

export const handleError: HandleErrorType = (error) => {
  console.log("ERROR FROM HANDLE ERROR", error?.status)
  if (error?.status === 400) {
    handle400Error(error)
  }
  if (error?.status === 401) {
    handle401Error(error)
  }
  if (error?.status === 524 || error?.status === "FETCH_ERROR") {
    handle524Error(error)
  }
}

export const showToastForResponseCode = (rc: number, message?: string) => {
  // Directly use the backend message if available
  if (message !== null && message !== undefined) {
    toast.dismiss()
    toast.error(message)
  } else {
    // Fallback or additional handling could go here if needed
    console.log(`Unhandled response code: ${rc}`)
  }
}
