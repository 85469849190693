import React from "react"
import { Typography, Box, Divider, Grid } from "@mui/material"
import logo from "../../assets/logo.svg"
import { formatDate } from "../../utils/formatDate"
import { type PrintDataTypes } from "../../types/IPrint"

const Print = React.forwardRef<HTMLElement, { printData: PrintDataTypes }>(({ printData }, ref) => {
  const content = printData?.map((item, index) =>
    item.key === "divider" ? (
      <Divider key={`divider-${String(index)}`} sx={{ my: 2 }} />
    ) : (
      <Grid
        key={item.key}
        gridTemplateColumns={"1fr 1fr"}
        sx={{ display: "grid", fontSize: "14px", wordBreak: "break-word" }}
      >
        <Grid>{item.label}:</Grid>
        <Grid>{item.value}</Grid>
      </Grid>
    )
  )
  const printedDate = formatDate(new Date().toLocaleString())

  return (
    <Box ref={ref} p={4}>
      <div className="header mb-4 flex justify-center">
        <img className="w-48" alt="Vgtc Logo" src={logo} />
      </div>
      {content}
      <Typography variant="caption">Printed on: {printedDate}</Typography>
    </Box>
  )
})

Print.displayName = "Print"
export default Print
