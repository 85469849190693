import { Grid } from "@mui/material"

interface PricingTableGridItemStyledProps {
  children: React.ReactNode
}

export const PricingTableGridItemStyled = ({ children }: PricingTableGridItemStyledProps) => (
  <Grid
    item
    sx={{
      display: "flex",
      justifyContent: "right",
      maxWidth: "13ch",
    }}
  >
    {children}
  </Grid>
)
