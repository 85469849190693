import { useEffect, useState } from "react"
import Box from "@mui/material/Box"
import BottomNavigation from "@mui/material/BottomNavigation"
import BottomNavigationAction from "@mui/material/BottomNavigationAction"
import { homeButtons } from "../data/homepage"
import { useUserContext } from "../context/UserContext"
import { useNavigate, useLocation } from "react-router-dom"

export function BottomNav() {
  const data = useUserContext()
  const navigate = useNavigate()
  const location = useLocation()

  const currentRouteIndex = homeButtons(data.signOutHandler)
    .slice(0, 4)
    .findIndex((value) => value.href === location.pathname)

  const [value, setValue] = useState(currentRouteIndex !== -1 ? currentRouteIndex : 0)

  const handleChange = (newValue: number) => {
    setValue(newValue)
    navigate(homeButtons(data.signOutHandler)[newValue].href ?? "/")
  }

  useEffect(() => {
    setValue(currentRouteIndex)
  }, [currentRouteIndex])

  return (
    <Box
      sx={{
        width: "100%",
        position: "fixed",
        bottom: 0,
        boxShadow: "0px -2px 10px rgba(0,0,0,0.2)",
        zIndex: 50,
      }}
    >
      <BottomNavigation
        showLabels
        value={value}
        onChange={(_, newValue) => {
          handleChange(newValue)
        }}
        sx={{ backgroundColor: "#14213D" }}
      >
        {homeButtons(data.signOutHandler)
          .slice(0, 4)
          .map((value, index) => (
            <BottomNavigationAction
              key={value.href}
              value={index}
              onClick={() => {
                navigate(value.href ?? "/")
              }}
              label={value.title}
              style={{ color: "white" }}
              icon={<value.Icon {...value.iconProps} />}
            />
          ))}
      </BottomNavigation>
    </Box>
  )
}
