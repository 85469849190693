import { createSlice, type PayloadAction } from "@reduxjs/toolkit"

interface HideHeaderState {
  value: boolean
}

const initialState: HideHeaderState = {
  value: true, // Initial state can be true or false depending on your needs
}

export const hideHeaderSlice = createSlice({
  name: "hideHeader",
  initialState,
  reducers: {
    // Modify hideHeader to accept a PayloadAction of type boolean
    hideHeader: (state, action: PayloadAction<boolean>) => {
      // Directly set the state value to the action payload
      state.value = action.payload
    },
  },
})

export const { hideHeader } = hideHeaderSlice.actions
export const hideHeaderReducer = hideHeaderSlice.reducer
