import { useMemo, useState, useEffect, useCallback, useRef } from "react"

import dayjs, { type Dayjs } from "dayjs"
import { useGetRevenueMutation } from "../../../redux/business/business.api"

import { useMediaQuery, useTheme } from "@mui/material"

import Alert, { type AlertColor } from "@mui/material/Alert"
import { LocationInputType, type HandleSnackBarType } from "../../../types/reportTypes"
import { ReportLayout } from "../../../layouts/ReportLayout"
import { ReportSkeleton } from "../../../layouts/Skeletons/ReportSkeleton"
import { getReportsHandler } from "../../../utils/ReportHandlerInput"
import { type Business, useFlattenRevenue } from "../../../middlewares/flattenRevenue"
import { RevenueReport } from "../../../components/Reports/Revenue/RevenueReport"
import { CalendarInputType } from "../../../types/reportTypes"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useDispatch } from "react-redux"
import { saveRevenueData } from "../../../redux/business/business.slice"

interface Props {
  openFiltersHandler?: () => void
}

export function Revenue(props: Props) {
  const [fetchReport] = useGetRevenueMutation()
  const [location, setLocation] = useState<string | null>(null)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState("")
  const [showHeaderDate, setShowHeaderDate] = useState(false)
  const [showTotalSummaries, setShowTotalSummaries] = useState(false)
  const hasHeaderDate = true
  const requiresAPICall = true
  const [reportDateRange, setReportDateRange] = useState<[Dayjs | null, Dayjs | null]>([
    dayjs(),
    dayjs(),
  ])
  const [headerDateRange, setHeaderDateRange] = useState<[Dayjs | null, Dayjs | null]>([null, null])
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>("error")

  const isMountedRef = useRef(true)

  const dispatch = useDispatch()

  const [report, setReport] = useState<Business | null>(null)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"))
  const [showForm, setShowForm] = useState(isMobile)
  const [isFetchingReport, setIsFetchingReport] = useState(false)
  const allLocationsOption = { name: "All Locations", id: null, businessId: null }
  const [selectedValue, setSelectedValue] = useState<any>(allLocationsOption)
  const [searchParams] = useSearchParams()

  const [firstSelectedValue, setFirstSelectedValue] = useState<any>(null)

  const navigate = useNavigate()

  useEffect(() => {
    // When the component mounts
    isMountedRef.current = true
    console.log("mounted")

    return () => {
      // When the component unmounts
      isMountedRef.current = false
      console.log("unmounted")
    }
  }, [])

  useEffect(() => {
    if (!isMobile) {
      setShowForm(true)
    }
  }, [isMobile])

  const toggleForm = useCallback(
    (show: boolean) => {
      if (!isMobile) {
        setShowForm(true)
      } else {
        setShowForm(show)
      }
    },
    [isMobile]
  )

  const handleLocationChange = (e: React.SyntheticEvent<Element, Event>, value: number | null) => {
    setLocation(value !== null ? value.toString() : null)
  }

  const handleSetSelectedValue = (value: any | null) => {
    setSelectedValue(value)
    if (firstSelectedValue === null) {
      setFirstSelectedValue(value)
    }
  }
  const handleSnackBar: HandleSnackBarType = (isOpen, severity, message) => {
    setSnackbarSeverity(severity)
    setSnackbarMessage(message)
    setSnackbarOpen(isOpen)
  }

  const flattenedReport = useMemo(() => {
    if (report != null) {
      return useFlattenRevenue({
        revenueData: report,
      })
    }
    return null
  }, [report])

  useEffect(() => {
    const handleOrientationChange = () => {
      // Explicitly check if flattenedReport and its stores property are not null or undefined.
      if (
        flattenedReport !== null &&
        flattenedReport !== undefined &&
        Object.keys(flattenedReport?.stores ?? {}).length > 0
      ) {
        if (Object.keys(flattenedReport.stores).length > 0) {
          setShowForm(false)
        }
      }
    }

    window.addEventListener("orientationchange", handleOrientationChange)

    // Cleanup the event listener when the component unmounts or the flattenedReport changes.
    return () => {
      window.removeEventListener("orientationchange", handleOrientationChange)
    }
  }, [flattenedReport?.stores])

  useEffect(() => {
    if (
      isMobile &&
      flattenedReport != null &&
      flattenedReport.stores != null &&
      Object.keys(flattenedReport.stores).length !== 0
    ) {
      toggleForm(false)
    }
  }, [report, isMobile, flattenedReport?.stores, toggleForm])

  // 360 menu redirect

  useEffect(() => {
    if (!searchParams.has("locationId")) {
      return
    }

    const startDateParam = searchParams.get("startDate")
    const endDateParam = searchParams.get("endDate")

    const startDate =
      startDateParam !== null && startDateParam !== ""
        ? dayjs(startDateParam)
        : dayjs().startOf("day")
    const endDate =
      endDateParam !== null && endDateParam !== "" ? dayjs(endDateParam) : dayjs().endOf("day")

    // Fetch the locationId from URL search parameters
    const locationId = searchParams.get("locationId") ?? ""
    const locationName = searchParams.get("locationName")
    const businessId = searchParams.get("businessId")

    // Determine the selected value based on the presence of locationId
    const newSelectedValue =
      locationId === ""
        ? allLocationsOption
        : {
            name: locationName,
            id: locationId,
            businessId,
          }

    setLocation(newSelectedValue.id)
    setSelectedValue(newSelectedValue)
    setFirstSelectedValue(newSelectedValue)
    setReportDateRange([startDate, endDate])
    setHeaderDateRange([startDate, endDate])
    getReportsHandler(
      fetchReport,
      setIsFetchingReport,
      handleSnackBar,
      setReport,
      newSelectedValue.id,
      hasHeaderDate,
      requiresAPICall,
      setShowHeaderDate,
      "data.data",
      {
        startDate: startDate.format("YYYY-MM-DD"),
        endDate: endDate.format("YYYY-MM-DD"),
        businessId: newSelectedValue.businessId,
      },
      isMountedRef,
      setShowTotalSummaries
    ).catch((error) => {
      console.log(error)
    })
  }, [])

  // saving flattenedReport data to redux

  useEffect(() => {
    if ((flattenedReport !== null) !== undefined) {
      dispatch(saveRevenueData(flattenedReport))
    }
  }, [flattenedReport])

  return (
    <ReportLayout
      reportName={"Revenue"}
      calendarInput={CalendarInputType.DateRange}
      locationInput={LocationInputType.SelectGetLoad}
      showHeaderDate={showHeaderDate}
      showTotalSummaries={showTotalSummaries}
      headerDateRange={headerDateRange}
      showForm={showForm}
      accordionHeadings={["Name", "Sales", "Redeem", "Net"]}
      showAccordionHeadings={flattenedReport != null}
      showSummaries={true}
      toggleForm={toggleForm}
      handleLocationChange={handleLocationChange}
      selectedValue={selectedValue}
      handleSetSelectedValue={handleSetSelectedValue}
      setReportDateRange={setReportDateRange}
      openFiltersHandler={props.openFiltersHandler}
      location={location}
      accordionSummaries={["Sales", "Redeem", "Net"]}
      getReportHandler={async () => {
        if (selectedValue.id === null) {
          // Create a new URLSearchParams object with the current search params
          const newSearchParams = new URLSearchParams(searchParams.toString())

          // Delete the parameters you want to clear
          newSearchParams.set("locationId", "")
          newSearchParams.set("startDate", reportDateRange[0]?.format("YYYY-MM-DD") ?? "")
          newSearchParams.set("endDate", reportDateRange[1]?.format("YYYY-MM-DD") ?? "")
          newSearchParams.delete("businessId")
          newSearchParams.delete("locationName")

          // Use the 'navigate' method with the 'replace' option
          navigate(`?${newSearchParams.toString()}`, { replace: true })
        } else if (selectedValue.id !== null && selectedValue.id !== undefined) {
          // Create a new URLSearchParams object with the current search params
          const newSearchParams = new URLSearchParams(searchParams.toString())

          // Set the search params as you had before
          newSearchParams.set("locationId", selectedValue.id)
          newSearchParams.set("businessId", selectedValue.businessId)
          newSearchParams.set("locationName", selectedValue.name)
          newSearchParams.set("startDate", reportDateRange[0]?.format("YYYY-MM-DD") ?? "")
          newSearchParams.set("endDate", reportDateRange[1]?.format("YYYY-MM-DD") ?? "")

          // Use the 'navigate' method with the 'replace' option
          navigate(`?${newSearchParams.toString()}`, { replace: true })
        }

        try {
          await getReportsHandler(
            fetchReport,
            setIsFetchingReport,
            handleSnackBar,
            setReport,
            location,
            hasHeaderDate,
            requiresAPICall,
            setShowHeaderDate,
            "data.data",

            {
              startDate: reportDateRange[0]?.startOf("day").format("YYYY-MM-DD"),
              endDate: reportDateRange[1]?.endOf("day").format("YYYY-MM-DD"),
              businessId: selectedValue.businessId,
            },
            isMountedRef,
            setShowTotalSummaries
          )

          setHeaderDateRange([reportDateRange[0], reportDateRange[1]])
          setFirstSelectedValue(selectedValue)
          localStorage.removeItem("option")
        } catch (error) {
          console.log(error)
        }
      }}
    >
      <>
        {isFetchingReport && <ReportSkeleton />}
        {!isFetchingReport &&
          flattenedReport !== null &&
          flattenedReport.stores !== null &&
          flattenedReport.stores !== undefined &&
          Object.keys(flattenedReport.stores).length === 0 &&
          snackbarOpen && (
            <Alert severity={snackbarSeverity} sx={{ width: "100%" }}>
              {snackbarMessage}
            </Alert>
          )}
        {!isFetchingReport &&
          flattenedReport !== null &&
          flattenedReport !== undefined &&
          flattenedReport.stores !== null &&
          flattenedReport.stores !== undefined && <RevenueReport report={flattenedReport} />}
      </>
    </ReportLayout>
  )
}
