import {
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
} from "@mui/material"
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined"
import CloseIcon from "@mui/icons-material/Close"
import { useEffect, useState } from "react"

interface LicensingHeaderProps {
  title: string
}

export function LicensingHeader({ title }: LicensingHeaderProps) {
  const [openDialog, setOpenDialog] = useState(false)
  const [dontShowAgain, setDontShowAgain] = useState(false)

  useEffect(() => {
    const showAgain = localStorage.getItem("dontShowInfoDialog")
    if (showAgain === "false") {
      setDontShowAgain(true)
    } else {
      setOpenDialog(true)
    }
  }, [])

  const handleOpenDialog = () => {
    setOpenDialog(true)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
    localStorage.setItem("dontShowInfoDialog", dontShowAgain ? "false" : "true")
  }

  const handleDontShowAgainChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked
    setDontShowAgain(checked)
    if (!checked) {
      localStorage.setItem("dontShowInfoDialog", "true")
    }
  }

  return (
    <div className="w-full sm:max-w-4xl mx-auto">
      <div className="flex justify-between items-center py-2 px-4 bg-[#14213D]">
        <div className="text-center flex-grow">
          <h1 className="text-white  font-bold text-2xl">{title}</h1>
        </div>
        <div className="absolute top-0 right-[14px] md:right-[17px] bottom-0 flex items-center h-[48px] overflow-hidden">
          <IconButton onClick={handleOpenDialog} sx={{ color: "#fff", padding: 0 }}>
            <HelpOutlineOutlinedIcon />
          </IconButton>
        </div>
      </div>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <div className="border-[3px] border-black">
          <DialogTitle sx={{ fontWeight: "700", textAlign: "center" }}>
            How do I get my terminal&apos;s Fill Request Code?
            <hr className="mt-3 border-[1px] border-black rounded-md" />
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            style={{ position: "absolute", right: "0px", top: "16px" }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
            <div className="text-black text-left">
              <h4 className="pb-2">
                1. Access your
                <span className="font-bold"> Terminal&apos;s Maintenance Menu</span>.
              </h4>
              <h4 className="py-2">
                2. Select <span className="font-bold">Terminal Diagnostics</span>.
              </h4>
              <h4 className="py-2">
                3. Press the <span className="font-bold">UPDATE</span> button in the
                <span className="font-bold"> License</span> section.
              </h4>
              <h4 className="py-2">
                4. The Terminal&apos;s <span className="font-bold">Fill Request Code</span> is
                displayed on screen as both a QR code and a 40-character code.
              </h4>
            </div>
            <FormControlLabel
              control={<Checkbox checked={dontShowAgain} onChange={handleDontShowAgainChange} />}
              label="Don't show this again"
            />
          </DialogContent>
        </div>
      </Dialog>
    </div>
  )
}
