import Button from "@mui/material/Button"
import CameraAltIcon from "@mui/icons-material/CameraAlt"
import ShoppingCartCheckoutOutlinedIcon from "@mui/icons-material/ShoppingCartCheckoutOutlined"
import DialpadIcon from "@mui/icons-material/Dialpad"
import FilterIcon from "@mui/icons-material/Filter"
import { useQRScanner } from "../../../hooks/useQRScanner"
import { useEffect, useState } from "react"
import { CustomDialog } from "../../CustomDialog"
import {
  Alert,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { useFillPricingMutation } from "../../../redux/business/business.api"
import CloseIcon from "@mui/icons-material/Close"
import NoPhotographyIcon from "@mui/icons-material/NoPhotography"
import { formatAmount } from "../../../utils/formatAmount"
import { PricingTableGridItemStyled } from "../../../styles/PricingTableGridItemStyled"
interface ScannerControlsProps {
  handleDecodeRequest: (newMachineCode: string, code?: string | undefined) => Promise<void>
  showCodeInput: boolean
  setShowCodeInput: (show: boolean) => void
  isSubmitting: boolean
  fillActionsLoading: boolean
  onScanned: (data: string) => void
}

interface PricingData {
  fillPricing: Record<string, number>
}

export const ScannerControls: React.FC<ScannerControlsProps> = ({
  handleDecodeRequest,
  showCodeInput,
  setShowCodeInput,
  isSubmitting,
  fillActionsLoading,
  onScanned,
}: ScannerControlsProps) => {
  const {
    videoRef,
    data,
    initiateScan,
    stopVideo,
    openScanDialog,
    handleScanClick,
    handleCloseDialog,
    handleFileUpload,
    isCameraDenied,
  } = useQRScanner()
  const [fetchPrices] = useFillPricingMutation()
  const [openPricingDialog, setOpenPricingDialog] = useState(false)
  const [pricingData, setPricingData] = useState<PricingData | null>(null)
  const [isFetchingPricing, setIsFetchingPricing] = useState(false)
  const [inputMachineCode, setInputMachineCode] = useState("")
  const MAX_PRICE_PER_COLUMN = 8

  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"))

  useEffect(() => {
    if (data.length > 0) {
      console.log("SCANNER DATA", data)
      onScanned(data)
      handleCloseDialog()
    }
  }, [data, onScanned])

  useEffect(() => {
    if (openScanDialog) {
      initiateScan()
    }
    return () => {
      // This function is called when the component unmounts
      stopVideo()
    }
  }, [openScanDialog, stopVideo])

  const handleOpenPricingDialog = async () => {
    setIsFetchingPricing(true)
    try {
      const response = await fetchPrices({}).unwrap()

      if (response?.rc === 1000) {
        setPricingData(response?.data)
        setOpenPricingDialog(true)
      } else {
        console.log("response checkout failed", response)
      }
    } catch (err) {
      const error = err as { status?: number; data?: unknown }
      console.log(error)
    } finally {
      setIsFetchingPricing(false)
    }
  }

  const handleClosePricingDialog = () => {
    setOpenPricingDialog(false)
  }

  const formatInputCode = (input: string) => {
    const upperCasedInput = input.toUpperCase()
    const filteredInput = upperCasedInput.replace(/[^A-Z0-9]/g, "") // Remove non-alphanumeric characters
    const limitedInput = filteredInput.slice(0, 40)
    const formattedCode = limitedInput.replace(/(.{5})/g, "$1-")
    return formattedCode.replace(/-+$/, "")
  }

  const [inputError, setInputError] = useState("")
  const handleInputChange = (e: { target: { value: string } }) => {
    const input = e.target.value.toUpperCase()
    if (/[0IO]/.test(input)) {
      setInputError("Invalid Code")
    } else {
      setInputError("")
    }

    const formattedCode = formatInputCode(input)
    setInputMachineCode(formattedCode)
  }

  const isPricingDataValid =
    pricingData !== null && Object.entries(pricingData.fillPricing).length >= MAX_PRICE_PER_COLUMN
  return (
    <>
      <div className="text-center">
        <Grid container spacing={1} paddingX={1}>
          <Grid item xs={6} lg={3}>
            <Button
              startIcon={<CameraAltIcon />}
              fullWidth
              disabled={isSubmitting || fillActionsLoading}
              variant="contained"
              color="primary"
              onClick={handleScanClick}
            >
              Scan QR
            </Button>
          </Grid>
          <Grid item xs={6} lg={3}>
            <Button
              startIcon={<FilterIcon />}
              fullWidth
              disabled={isSubmitting || fillActionsLoading}
              variant="contained"
              color="primary"
              component="label"
            >
              Upload QR
              <input type="file" accept="image/*" className="hidden" onChange={handleFileUpload} />
            </Button>
          </Grid>
          <Grid item xs={6} lg={3}>
            <Button
              disabled={isSubmitting || fillActionsLoading}
              startIcon={<DialpadIcon />}
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => {
                setShowCodeInput(true)
              }}
            >
              Enter Code
            </Button>
          </Grid>
          <Grid item xs={6} lg={3}>
            <Button
              startIcon={<ShoppingCartCheckoutOutlinedIcon />}
              disabled={isSubmitting || fillActionsLoading || isFetchingPricing}
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => {
                handleOpenPricingDialog().catch((error) => {
                  console.error("Error during checkout:", error)
                })
              }}
            >
              {isFetchingPricing && (
                <CircularProgress
                  size={24}
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
              Pricing
            </Button>
          </Grid>
        </Grid>
      </div>
      <CustomDialog
        open={openScanDialog}
        title="Scan QR"
        centerTitle={true}
        fullScreen={false}
        hideCloseIcon={true}
        content={
          <Box className="block relative w-full h-[350px] overflow-hidden">
            {isCameraDenied ? (
              <div>
                <Alert
                  icon={false}
                  severity="error"
                  className="w-full h-[350px] object-cover justify-center"
                >
                  <div className="flex flex-col items-center w-full h-full  justify-around">
                    <NoPhotographyIcon sx={{ fontSize: "154px" }} />
                    <Typography variant="body1">
                      Camera access denied. Please enable camera access in your browser settings.
                    </Typography>
                  </div>
                </Alert>
              </div>
            ) : (
              <video
                ref={videoRef}
                className="w-full h-full object-cover"
                playsInline
                muted
                autoPlay
              />
            )}
          </Box>
        }
        primaryActionText="Cancel"
        secondaryActionText={isCameraDenied ? "Retry Camera Access" : undefined}
        onPrimaryAction={handleCloseDialog}
        onSecondaryAction={() => {
          initiateScan()
        }}
        handleClose={handleCloseDialog}
      />

      <CustomDialog
        open={showCodeInput}
        title="Enter Code"
        centerTitle={true}
        isLoading={isSubmitting}
        fullScreen={false}
        hideCloseIcon={true}
        content={
          <TextField
            disabled={isSubmitting}
            autoFocus
            multiline
            label="Fill Request code"
            variant="outlined"
            margin="normal"
            fullWidth
            value={inputMachineCode}
            onChange={handleInputChange}
            error={inputError !== null ? inputError !== "" : false}
            helperText={inputError}
          />
        }
        primaryActionText="Submit"
        secondaryActionText="Cancel"
        disabledPrimaryAction={
          isSubmitting ||
          inputMachineCode.replace(/-/g, "").length < 40 ||
          inputError === "Invalid Code"
        }
        disabledSecondaryAction={isSubmitting}
        onPrimaryAction={() => {
          handleDecodeRequest(inputMachineCode)
            .then(() => {
              setInputMachineCode("")
            })
            .catch((err) => {
              console.error("Error submitting code:", err)
            })
        }}
        onSecondaryAction={() => {
          setShowCodeInput(false)
          setInputMachineCode("")
          setInputError("")
        }}
        handleClose={() => {
          setShowCodeInput(false)
          setInputMachineCode("")
          setInputError("")
        }}
      />
      <Dialog
        open={openPricingDialog}
        onClose={handleClosePricingDialog}
        className="rounded-md"
        PaperProps={{ style: { width: "100%" } }}
      >
        <div className="w-full mx-auto">
          <DialogTitle style={{ padding: "0" }} className="bg-[#14213D] h-[48px] relative">
            <div className="flex justify-center items-center text-white p-2">
              <h1 className="font-bold">Pricing</h1>
            </div>
            <div className="absolute top-0 right-0 bottom-0 m-auto flex items-center">
              <IconButton aria-label="close" onClick={handleClosePricingDialog}>
                <CloseIcon className="text-white" />
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent sx={{ padding: 0 }}>
            <>
              <Grid
                container
                spacing={0}
                className="font-bold text-right"
                sx={{ display: "grid", paddingY: "4px" }}
                gridTemplateColumns={
                  !isPricingDataValid || isSmallScreen ? "1fr 1fr" : "1fr 1fr 1fr 1fr"
                }
              >
                <Grid sx={{ maxWidth: "13ch" }} item>
                  Fill
                </Grid>
                <Grid sx={{ maxWidth: "13ch" }} item>
                  Price
                </Grid>
                {isPricingDataValid && !isSmallScreen && (
                  <>
                    <Grid sx={{ maxWidth: "13ch" }} item>
                      Fill
                    </Grid>
                    <Grid sx={{ maxWidth: "13ch" }} item>
                      Price
                    </Grid>
                  </>
                )}
              </Grid>
              <Grid
                container
                sx={{
                  display: "grid",
                }}
                gridTemplateColumns={!isPricingDataValid || isSmallScreen ? "1fr" : "1fr 1fr"}
              >
                <div>
                  {pricingData !== null &&
                    Object.entries(pricingData.fillPricing)
                      .slice(0, MAX_PRICE_PER_COLUMN)
                      .map(([key, value], index) => (
                        <Grid
                          key={key}
                          item
                          container
                          sx={{
                            backgroundColor: index % 2 === 0 ? "#E5E5E5" : "#F2F2F2",
                            alignItems: "center",
                            display: "grid",
                            paddingY: "4px",
                          }}
                          gridTemplateColumns={"1fr 1fr"}
                        >
                          <PricingTableGridItemStyled>{key}</PricingTableGridItemStyled>
                          <PricingTableGridItemStyled>
                            {formatAmount(value).full}
                          </PricingTableGridItemStyled>
                        </Grid>
                      ))}
                </div>
                <div>
                  {isPricingDataValid && (
                    <Grid container spacing={0}>
                      {pricingData !== null &&
                        Object.entries(pricingData.fillPricing)
                          .slice(MAX_PRICE_PER_COLUMN)
                          .map(([key, value], index) => (
                            <Grid
                              key={key}
                              item
                              xs={12}
                              container
                              sx={{
                                backgroundColor:
                                  index % 2 === (isSmallScreen ? 0 : 1) ? "#E5E5E5" : "#F2F2F2",
                                alignItems: "center",
                                display: "grid",
                                paddingY: "4px",
                              }}
                              gridTemplateColumns={"1fr 1fr"}
                            >
                              <PricingTableGridItemStyled>{key}</PricingTableGridItemStyled>
                              <PricingTableGridItemStyled>
                                {formatAmount(value).full}
                              </PricingTableGridItemStyled>
                            </Grid>
                          ))}
                    </Grid>
                  )}
                </div>
              </Grid>
            </>
          </DialogContent>
        </div>
      </Dialog>
    </>
  )
}
