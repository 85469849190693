import { useEffect, useState } from "react"
import Button from "@mui/material/Button"
import CssBaseline from "@mui/material/CssBaseline"
import TextField from "@mui/material/TextField"
import FormControlLabel from "@mui/material/FormControlLabel"
import Checkbox from "@mui/material/Checkbox"
import { toast } from "react-hot-toast"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Container from "@mui/material/Container"
import { useUserContext } from "../../context/UserContext"
import CircularProgress from "@mui/material/CircularProgress"
import logo from "../../assets/logo.svg"
// import { TextFieldPassword } from "../../components/TextFieldPassword"
import { Email, Visibility, VisibilityOff } from "@mui/icons-material"
import { Icon, InputAdornment, IconButton } from "@mui/material"

export const Login = () => {
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState(false)
  const [rememberMe, setRememberMe] = useState(false)
  const data = useUserContext()
  const [showPassword, setShowPassword] = useState(false)

  useEffect(() => {
    if (localStorage.getItem("rememberMe") === "true") {
      setUsername(localStorage.getItem("username") ?? "")
      setPassword(localStorage.getItem("password") ?? "")
      setRememberMe(true)
    }
  }, [])

  const loginHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setLoading(true)

    if (!navigator.onLine) {
      toast.dismiss()
      toast.error("You are offline. Please check your internet connection and try again later")
      setLoading(false)
      return
    }

    // Add field validation checks
    if (username === "") {
      toast.dismiss()
      toast.error("Please enter a username")
      setLoading(false)
      return
    }

    if (password === "") {
      toast.dismiss()
      toast.error("Please enter a password")
      setLoading(false)
      return
    }

    if (rememberMe) {
      localStorage.setItem("username", username)
      localStorage.setItem("password", password)
      localStorage.setItem("rememberMe", "true")
    } else {
      localStorage.removeItem("username")
      localStorage.removeItem("password")
      localStorage.setItem("rememberMe", "false")
    }

    try {
      await data.loginHandler(username, password)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img src={logo} alt="Logo" className="w-48 mb-4" />
        <Box
          component="form"
          onSubmit={(event) => {
            event.preventDefault()
            loginHandler(event).catch((error) => {
              console.error("An error occurred during login:", error)
            })
          }}
          noValidate
          sx={{ mt: 1 }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            value={username}
            onChange={(e) => {
              setUsername(e.target.value)
            }}
            autoFocus
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Box sx={{ margin: "0px -12px 0px 0px", padding: "8px" }}>
                    <Icon component={Email} />
                  </Box>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type={showPassword ? "text" : "password"}
            id="numeric"
            onChange={(e) => {
              setPassword(e.target.value)
            }}
            value={password}
            autoComplete="off"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      setShowPassword(!showPassword)
                    }}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                value="remember"
                color="primary"
                checked={rememberMe}
                onChange={(e) => {
                  setRememberMe(e.target.checked)
                }}
              />
            }
            label="Remember me"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={loading}
            sx={{
              mt: 3,
              mb: 2,
              position: "relative",
            }}
          >
            {loading && (
              <CircularProgress
                size={24}
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
            Sign In
          </Button>
        </Box>
        <Typography variant="body2">v{process.env.REACT_APP_VERSION_NUMBER}</Typography>
      </Box>
    </Container>
  )
}
