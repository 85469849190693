import type {
  SalesStore,
  Summary,
  InputJson,
  FlattenedSalesReport,
} from "../types/ISalesReportTypes"

export function flattenSales(input: InputJson): FlattenedSalesReport {
  const salesData = input.salesData.businessSalesReport
  const groupedSalesReport: Record<
    string,
    { name: string; stores: SalesStore[]; totalSummary: Summary }
  > = {}

  // Check if salesData is an array before iterating
  if (!Array.isArray(salesData)) {
    console.error("salesData is not an array:", salesData)
    return {
      stores: groupedSalesReport,
      totalSummary: input.salesData.totalSummary,
    }
  }

  for (const businessSales of salesData) {
    const { business, salesReport, totalSummary } = businessSales

    if (typeof business?.businessInfo !== "undefined") {
      const { businessId, name } = business.businessInfo

      groupedSalesReport[businessId] = {
        name,
        stores: salesReport,
        totalSummary,
      }
    }
  }

  const sortedGroupedSalesReport: Record<
    string,
    { name: string; stores: SalesStore[]; totalSummary: Summary }
  > = {}

  Object.entries(groupedSalesReport)
    .sort((a, b) => a[1].name.localeCompare(b[1].name, undefined, { numeric: true }))
    .forEach(([key, value]) => {
      sortedGroupedSalesReport[key] = value
    })

  const outputJson: FlattenedSalesReport = {
    stores: sortedGroupedSalesReport,
    totalSummary: input.salesData.totalSummary,
  }

  console.log("outputJson:", Object.entries(sortedGroupedSalesReport))
  return outputJson
}
