import { BottomNav } from "../components/BottomNav"
import { Sidebar } from "../components/Sidebar"

interface Props {
  children: React.ReactNode
}

export function SidebarLayout({ children }: Props) {
  return (
    <div className="relative">
      <div className="hidden sm:block absolute left-0 z-55">
        <Sidebar />
      </div>
      <div className="block sm:hidden absolute bottom-0 z-55">
        <BottomNav />
      </div>
      <div className="px-0 sm:px-20 pt-0  pb-16 sm:pb-0">{children}</div>
    </div>
  )
}
