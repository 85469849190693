import { Outlet, useNavigate } from "react-router-dom"
// import { useSelector } from "react-redux"
// import { type RootState } from "../redux/store"
import { useEffect } from "react"
// import { useVerifyTokenQuery } from "../redux/user/user.api"

export function AuthLayout() {
  // const user = useSelector((state: RootState) => state.user.user)
  const navigate = useNavigate()
  // const { error, isLoading: isTokenLoading } = useVerifyTokenQuery("")

  useEffect(() => {
    const token = localStorage.getItem("token")
    if (token === null || token === undefined || token === "") {
      navigate("/login")
    }
  }, [navigate])

  return <Outlet />
}
