import { Backdrop, CircularProgress } from "@mui/material"
import { PurchaseDetails } from "../../../components/Licensing/TransactionDetails/TransactionDetails"
import { LicensingHeader } from "../../../components/LicensingHeader"
import {
  useGetCreateInvoiceQuery,
  usePaymentStatusQuery,
} from "../../../redux/business/business.api"
import type { MachineData } from "../../../types/paymentStatusTypes"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

export function PaymentStatus() {
  const orderId = localStorage.getItem("orderId")
  const navigate = useNavigate()

  const {
    data: creditStatusData,
    isLoading: isCreditLoading,
    error: creditErrorData,
  } = usePaymentStatusQuery(
    {
      orderId,
    },
    {
      skip: orderId === null || orderId === undefined || orderId === "",
    }
  )

  const {
    data: invoiceStatusData,
    isLoading: isInvoiceLoading,
    error: invoiceErrorData,
  } = useGetCreateInvoiceQuery(
    {},
    {
      skip: orderId !== null && orderId !== undefined && orderId !== "",
    }
  )

  const paymentStatusData = creditStatusData ?? invoiceStatusData
  const isLoading = isCreditLoading || isInvoiceLoading
  const error = creditErrorData ?? invoiceErrorData

  useEffect(() => {
    if (!isLoading && paymentStatusData !== null && paymentStatusData !== undefined) {
      if (paymentStatusData.rc === 1000) {
        console.log("paymentSuccess")
      } else {
        console.log("Something went wrong.")
        navigate("/licensing/purchase-fills")
      }
    }
    return () => {
      localStorage.removeItem("orderId")
    }
  }, [paymentStatusData, isLoading])

  if (isLoading) {
    return (
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    )
  }

  if (error !== null && error !== undefined && !isLoading) {
    return <div>Error retrieving payment data.</div>
  }

  console.log("paymentStatusData in PaymentStatus: ", paymentStatusData)

  const transaction = paymentStatusData?.data?.transaction[0]
  const machineData = transaction?.machines?.map((machine: MachineData) => ({
    ...machine,
    filCount: machine.fillCount,
    fillPurchased: machine.fillPurchased,
    code: machine.fillCode,
    price: machine.price,
  }))

  return (
    <div className="w-full sm:max-w-4xl mx-auto mb-20">
      <nav className="shadow-top shadow-bottom:hidden border-b border-gray-300 sticky top-0 z-50 bg-white">
        <LicensingHeader title={"Transaction Complete"} />
      </nav>
      {transaction !== null && transaction !== undefined && (
        <PurchaseDetails
          transactionId={transaction.transactionId}
          purchaseDate={transaction.purchaseDate}
          purchaseTotal={transaction.purchaseTotal.toString()}
          machineData={machineData}
        />
      )}
    </div>
  )
}
