import { type SvgIconProps } from "@mui/material"
import React from "react"
import { Link } from "react-router-dom"

interface Props {
  children: string
  onClick?: (event: React.MouseEvent) => void
  href?: string
  className?: string
  icon: React.ReactNode
  iconProps?: SvgIconProps
  isLocationsEmpty?: boolean
}

export function HomeButton(props: Props) {
  // Determine if the button is "Account Settings" or "Sign Out"
  const isAlwaysEnabled = props.href === "/settings" || props.onClick?.name === "signOutHandler"

  const baseClassName =
    "flex rounded p-2 w-full text-center mb-4 shadow cursor-pointer justify-start items-center select-none"
  const disabledClassName = "bg-gray-400 hover:bg-gray-400 cursor-not-allowed"
  const enabledClassName = "bg-[#14213D] hover:bg-[#FCA311] active:bg-[#FCA311]"
  const className = `${baseClassName} ${
    (props.isLocationsEmpty ?? false) && !isAlwaysEnabled ? disabledClassName : enabledClassName
  }`

  const iconClass = "flex-shrink-0 ml-4"
  const textClass = "text-left flex-grow ml-4 text-white"

  const handleClick = (event: React.MouseEvent) => {
    if ((props.isLocationsEmpty ?? false) && !isAlwaysEnabled) {
      event.preventDefault()
    } else {
      props.onClick?.(event)
    }
  }

  return props.href != null ? (
    <Link
      to={props.href}
      className={className}
      onClick={handleClick}
      style={{
        pointerEvents: (props.isLocationsEmpty ?? false) && !isAlwaysEnabled ? "none" : "auto",
      }}
    >
      <span className={iconClass}>{props.icon}</span>
      <span className={textClass}>{props.children}</span>
    </Link>
  ) : (
    <div className={className} onClick={handleClick}>
      <span className={iconClass}>{props.icon}</span>
      <span className={textClass}>{props.children}</span>
    </div>
  )
}
