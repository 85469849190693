import React from "react"

import { AccordionCashItem } from "./AccordionCurrentItem"
import { Alert } from "@mui/material"

interface Terminal {
  terminalName: string
  cashStatusDateTime: string
  recycler1Count: number
  recycler2Count: number
  stackerCount: number
  recycler1Value: number
  recycler2Value: number
  stackerValue: number
  deviceStatuses: any
}

interface OutputLocation {
  locationId: string
  totalLocationRecycler1Count: number
  totalLocationRecycler2Count: number
  totalLocationStackerCount: number
  totalLocationRecycler1Value: number
  totalLocationRecycler2Value: number
  totalLocationStackerValue: number
  terminals: Terminal[]
}

interface OutputBusiness {
  businessId: string
  businessName: string
  totalBusinessRecycler1Count: number
  totalBusinessRecycler2Count: number
  totalBusinessStackerCount: number
  totalBusinessRecycler1Value: number
  totalBusinessRecycler2Value: number
  totalBusinessStackerValue: number
  locations: OutputLocation[]
}

interface Props {
  report: OutputBusiness[]
}

export function Report({ report }: Props) {
  const collapseAllBusinesses = report.length === 1
  // filter devices function
  const hasDeviceStatuses = report.some((business) =>
    business.locations.some((location) =>
      location.terminals.some(
        (terminal) =>
          terminal.deviceStatuses !== null &&
          terminal.deviceStatuses !== undefined &&
          Object.keys(terminal.deviceStatuses).length > 0
      )
    )
  )

  if (!hasDeviceStatuses) {
    return (
      <Alert severity="error" sx={{ width: "100%" }}>
        No data to show with the current selection!
      </Alert>
    )
  }

  return (
    <div className="shadow-lg">
      {report.map(
        (
          {
            businessId,
            businessName,
            totalBusinessRecycler1Count,
            totalBusinessRecycler1Value,
            totalBusinessRecycler2Count,
            totalBusinessRecycler2Value,
            totalBusinessStackerCount,
            totalBusinessStackerValue,
            locations,
          },
          index
        ) => {
          return (
            <React.Fragment key={index}>
              <AccordionCashItem
                collapseAllBusinesses={collapseAllBusinesses}
                business={{
                  businessId,
                  businessName,
                  totalBusinessRecycler1Count,
                  totalBusinessRecycler1Value,
                  totalBusinessRecycler2Count,
                  totalBusinessRecycler2Value,
                  totalBusinessStackerCount,
                  totalBusinessStackerValue,
                  locations,
                }}
              />
            </React.Fragment>
          )
        }
      )}
    </div>
  )
}
