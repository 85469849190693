import { useState } from "react"
import { formatAmount } from "../../../utils/formatAmount"
import { Alert, Backdrop, Button, Typography } from "@mui/material"
import ShoppingCartCheckoutOutlinedIcon from "@mui/icons-material/ShoppingCartCheckoutOutlined"
import { ReactComponent as AnimatedShoppingCard } from "../../../assets/licensing/animated_shopping_cart.svg"
import { useNavigate } from "react-router-dom"

interface CheckoutProps {
  getTotalPurchase: () => number
  isSubmitting: boolean
  setFillActionsLoading: React.Dispatch<React.SetStateAction<boolean>>
  fillActionsLoading: boolean
  allMachinesHavePrices: boolean
}

export const Checkout: React.FC<CheckoutProps> = ({
  getTotalPurchase,
  setFillActionsLoading,
  fillActionsLoading,
  allMachinesHavePrices,
  isSubmitting,
}: CheckoutProps) => {
  const [spinnerCheckout, setSpinnerCheckout] = useState(false)

  const navigate = useNavigate()

  const handleCheckout = async () => {
    setFillActionsLoading(true)
    setSpinnerCheckout(true)
    try {
      navigate("/licensing/order-summary")
    } catch (error) {
      console.error("Checkout error:", error)
    } finally {
      setSpinnerCheckout(false)
      setFillActionsLoading(false)
    }
  }

  return (
    <div className="flex justify-end  items-center px-2">
      {spinnerCheckout && (
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: 2147483647, // The highest possible z-index value
            position: "fixed", // Change from absolute to fixed
            top: 0, // Ensure it starts from the very top of the viewport
            left: 0, // Ensure it starts from the very left of the viewport
            width: "100vw", // Cover the full viewport width
            height: "100vh", // Cover the full viewport height
            display: "flex",
            flexDirection: "column",
            justifyContent: "center", // Center spinner vertically
          }}
          open={true}
        >
          <AnimatedShoppingCard />
        </Backdrop>
      )}

      {getTotalPurchase() > 50000 && allMachinesHavePrices ? (
        <Alert severity="warning" sx={{ width: "100%" }}>
          {/* to Rus parametrize the value */}
          <Typography variant="body1">
            Your purchase cannot be be over $50,000 in a single transaction. Please adjust your
            cart.
          </Typography>
        </Alert>
      ) : (
        <Button
          startIcon={<ShoppingCartCheckoutOutlinedIcon />}
          disabled={
            isSubmitting || fillActionsLoading || getTotalPurchase() === 0 || !allMachinesHavePrices
          }
          variant="contained"
          sx={{
            backgroundColor: "#FCA311",
            color: "white",
            height: "36.5px",
            "&:hover": {
              backgroundColor: "rgba(252, 163, 17, 0.8)",
            },
            "&.Mui-disabled": {
              backgroundColor: "rgba(252, 163, 17, 0.3)",
            },
          }}
          onClick={() => {
            handleCheckout().catch((error) => {
              console.error("Error during checkout:", error)
            })
          }}
        >
          Go To Checkout
          {allMachinesHavePrices && (
            <span className="font-bold pl-1">{formatAmount(getTotalPurchase()).full}</span>
          )}
        </Button>
      )}
    </div>
  )
}
