import React, { useState } from "react"
import DateRangeIcon from "@mui/icons-material/DateRange"
import ArrowRightAltOutlinedIcon from "@mui/icons-material/ArrowRightAltOutlined"
import { DatePicker, Button } from "antd"
import type { RangePickerProps } from "antd/es/date-picker"
import { Typography } from "@mui/material"
import dayjs, { type Dayjs } from "dayjs"
import "./DateRangePicker.css"

const { RangePicker } = DatePicker

interface Props {
  value: [Dayjs, Dayjs] | null
  onChange: (dates: Array<Dayjs | null> | null, dateStrings: string[]) => void
  disabled?: boolean
  setGiveMargin: React.Dispatch<React.SetStateAction<boolean>>
}

interface CustomFooterProps {
  onClick: (value: [Dayjs, Dayjs]) => void
  closePicker: () => void
}

const adjustWeekStart = (date: Dayjs) => {
  if (dayjs().day() === 0) {
    return date.startOf("week").day() === 0
      ? date.startOf("week").subtract(6, "day")
      : date.startOf("week")
  } else {
    return date.startOf("week").day() === 0
      ? date.startOf("week").add(1, "day")
      : date.startOf("week")
  }
}

const adjustWeekEnd = (date: Dayjs) => {
  if (dayjs().day() === 0) {
    return date.startOf("week").day() === 0
      ? date.startOf("week")
      : date.startOf("week").add(6, "day")
  } else {
    return date.startOf("week").day() === 0
      ? date.startOf("week").add(7, "day")
      : date.startOf("week").add(6, "day")
  }
}

const getRangePresets = (): Array<{
  label: string
  value: [Dayjs, Dayjs]
}> => [
  { label: "Today", value: [dayjs().startOf("day"), dayjs().endOf("day")] },
  {
    label: "Yesterday",
    value: [dayjs().subtract(1, "day").startOf("day"), dayjs().subtract(1, "day").endOf("day")],
  },
  { label: "This Week", value: [adjustWeekStart(dayjs()), dayjs().endOf("day")] },
  {
    label: "Last Week",
    value: [
      adjustWeekStart(dayjs().subtract(1, "week")),
      adjustWeekEnd(dayjs().subtract(1, "week")),
    ],
  },
  { label: "This Month", value: [dayjs().startOf("month"), dayjs()] }, // and here
  {
    label: "Last Month",
    value: [
      dayjs().subtract(1, "month").startOf("month"),
      dayjs().subtract(1, "month").endOf("month"),
    ],
  },
  { label: "This Year", value: [dayjs().startOf("year"), dayjs()] }, // and here
]

// eslint-disable-next-line arrow-body-style
const disabledDate: RangePickerProps["disabledDate"] = (current) => {
  // Can not select days before today and today
  return dayjs().endOf("day") < current
}

const separatorIcon = <ArrowRightAltOutlinedIcon />

const CustomFooter: React.FC<CustomFooterProps> = ({ onClick, closePicker }) => {
  return (
    <div className="presets">
      {getRangePresets().map(({ label, value }) => (
        <Button
          key={label}
          size="large"
          onClick={() => {
            onClick(value)
            closePicker()
          }}
        >
          {label}
        </Button>
      ))}
      <Typography variant="caption" sx={{ color: "#000000", opacity: 0.6, fontSize: "0.65rem" }}>
        Transactions before 4:00am belong to previous day
      </Typography>
    </div>
  )
}

export function DateRangePicker({ value, onChange, disabled = false, setGiveMargin }: Props) {
  const [selectedRange, setSelectedRange] = useState<[Dayjs, Dayjs] | null>(value)
  const [isOpen, setIsOpen] = useState(false)

  const handleRangeChange = (dates: Array<Dayjs | null> | null, dateStrings: string[]) => {
    // logic to controll the visible panel should go here
    // on page load "from" and "to" are set to first panel because value is intialized to currentDate
    // if the selectedRange[0].month < current month then "to" populates panel 2
    // so to fix the issue, should watch for selectedRangeChange[0].month and based on that
    // AND if the "to" input field is focused/active then panel 2 should be visible and panel 1 hidden
    // if the "from" input field is focused/active then panel 1 should be visible and panel 2 hidden
    // if the elements in the header of the panel container are clicked: ant-picker-month-btn,
    // ant-picker-year-btn the code controlling the
    // visibiilty of the panels should be reset, since clicking the panel headers removes panel 2
    // from DOM and only panel 1 is left.
    //
    setSelectedRange(dates as [Dayjs, Dayjs] | null)
    onChange(dates, dateStrings)
  }

  const handleOpenChange = (open: boolean) => {
    setIsOpen(open)
    setGiveMargin(open)
    window.scrollTo(0, 0)
  }

  return (
    <RangePicker
      placement="bottomRight"
      suffixIcon={
        <DateRangeIcon
          onClick={() => {
            setIsOpen(!isOpen)
            setGiveMargin(!isOpen)
            window.scrollTo(0, 0)
          }}
        />
      }
      separator={separatorIcon}
      className="range-picker"
      size="large"
      inputReadOnly
      open={isOpen}
      allowClear={false}
      value={selectedRange}
      onChange={handleRangeChange}
      onOpenChange={handleOpenChange}
      disabledDate={disabledDate}
      disabled={disabled}
      renderExtraFooter={() => (
        <CustomFooter
          onClick={(value) => {
            handleRangeChange(
              value,
              value.map((d: Dayjs) => d.format("YYYY-MM-DD"))
            )
          }}
          closePicker={() => {
            setIsOpen(false)
            setGiveMargin(false)
          }}
        />
      )}
    />
  )
}
