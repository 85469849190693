import { useMemo, useState, useEffect, useCallback, useRef } from "react"
import dayjs, { type Dayjs } from "dayjs"
import { useGetCashAdjustmentsMutation } from "../../../redux/business/business.api"
import { Report } from "../../../components/Reports/CashAdjustments"
import { flattenAdjustments } from "../../../middlewares/flattenAdjustments"
import { ReportSkeleton } from "../../../layouts/Skeletons/ReportSkeleton"

import { useMediaQuery, useTheme } from "@mui/material"

import Alert, { type AlertColor } from "@mui/material/Alert"
import { resetFilters } from "../../../redux/filters/filters.slice"
import { useDispatch } from "react-redux"
import { type HandleSnackBarType, LocationInputType } from "../../../types/reportTypes"
import { ReportLayout } from "../../../layouts/ReportLayout"
import { getReportsHandler } from "../../../utils/ReportHandlerInput"
import { CalendarInputType } from "../../../types/reportTypes"
import { useNavigate, useSearchParams } from "react-router-dom"
import { EncryptStorage } from "encrypt-storage"
const storage = new EncryptStorage("jfkejfklrjklgjerlk485859809gjtkljkgjl")

interface Props {
  openFiltersHandler?: () => void
}

export function CashAdjustments(props: Props) {
  const [fetchReport] = useGetCashAdjustmentsMutation()
  const [location, setLocation] = useState<string | null>(null)

  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState("")
  const [showHeaderDate, setShowHeaderDate] = useState(false)
  const hasHeaderDate = true
  const requiresAPICall = true
  const [reportDateRange, setReportDateRange] = useState<[Dayjs | null, Dayjs | null]>([
    dayjs(),
    dayjs(),
  ])
  const [headerDateRange, setHeaderDateRange] = useState<[Dayjs | null, Dayjs | null]>([null, null])
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>("error")

  const [report, setReport] = useState([])

  const isMountedRef = useRef(true)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"))
  const [showForm, setShowForm] = useState(isMobile)
  const [isFetchingReport, setIsFetchingReport] = useState(false)
  const allLocationsOption = { name: "All Locations", id: null, businessId: null }
  const [selectedValue, setSelectedValue] = useState<any>(allLocationsOption)
  const dispatch = useDispatch()

  const [firstSelectedValue, setFirstSelectedValue] = useState<any>(null)

  const [searchParams] = useSearchParams()

  const navigate = useNavigate()

  // when component is unmounted we reset the state of the filters i'm using cleanup function in class based it's componentWillUnmount

  useEffect(() => {
    // When the component mounts
    isMountedRef.current = true

    return () => {
      // When the component unmounts
      isMountedRef.current = false
      dispatch(resetFilters())
    }
  }, [])

  useEffect(() => {
    if (!isMobile) {
      setShowForm(true)
    }
  }, [isMobile])

  const toggleForm = useCallback(
    (show: boolean) => {
      if (!isMobile) {
        setShowForm(true)
      } else {
        setShowForm(show)
      }
    },
    [isMobile]
  )

  const handleLocationChange = (e: React.SyntheticEvent<Element, Event>, value: number | null) => {
    setLocation(value !== null ? value.toString() : null)
  }

  const handleSetSelectedValue = (value: any | null) => {
    setSelectedValue(value)
    if (firstSelectedValue === null) {
      setFirstSelectedValue(value)
    }
  }

  const handleSnackBar: HandleSnackBarType = (isOpen, severity, message) => {
    setSnackbarSeverity(severity)
    setSnackbarMessage(message)
    setSnackbarOpen(isOpen)
  }

  const flattenedReport = useMemo(() => {
    const locations = storage.getItem("locations")
    return flattenAdjustments({
      businesses: report,
      locations: locations ?? [],
    })
  }, [report])

  // this useEffect allows us to navigate through browser back and forward button
  // uncomment the code below for browser back and forward button

  useEffect(() => {
    // If locationId is not in the URL parameters, don't run the effect.
    if (!searchParams.has("locationId")) {
      return
    }

    const startDateParam = searchParams.get("startDate")
    const endDateParam = searchParams.get("endDate")

    const startDate =
      startDateParam !== null && startDateParam !== ""
        ? dayjs(startDateParam)
        : dayjs().startOf("day")
    const endDate =
      endDateParam !== null && endDateParam !== "" ? dayjs(endDateParam) : dayjs().endOf("day")

    // Fetch the locationId from URL search parameters
    const locationId = searchParams.get("locationId") ?? ""
    const locationName = searchParams.get("locationName")
    const businessId = searchParams.get("businessId")

    // Determine the selected value based on the presence of locationId
    const newSelectedValue =
      locationId === ""
        ? allLocationsOption
        : {
            name: locationName,
            id: locationId,
            businessId,
          }

    // Update the component state with the new selected value
    setLocation(newSelectedValue.id)
    setSelectedValue(newSelectedValue)
    setFirstSelectedValue(newSelectedValue)
    setReportDateRange([startDate, endDate])
    setHeaderDateRange([startDate, endDate])

    // Make the API call with the determined parameters
    getReportsHandler(
      fetchReport,
      setIsFetchingReport,
      handleSnackBar,
      setReport,
      newSelectedValue.id,
      hasHeaderDate,
      requiresAPICall,
      setShowHeaderDate,
      "data.data.businesses",
      {
        startDate: startDate.format("YYYY-MM-DD"),
        endDate: endDate.format("YYYY-MM-DD"),
        businessId: newSelectedValue.businessId,
        terminalIds: ["All Types"],
      },
      isMountedRef
    ).catch((error) => {
      console.log(error)
    })
  }, [])

  useEffect(() => {
    const handleOrientationChange = () => {
      // We'll close the form on any orientation change if there's content in flattenedReport.
      if (flattenedReport.length > 0) {
        setShowForm(false)
      }
    }

    window.addEventListener("orientationchange", handleOrientationChange)

    // Cleanup the event listener when the component unmounts or the flattenedReport changes.
    return () => {
      window.removeEventListener("orientationchange", handleOrientationChange)
    }
  }, [flattenedReport.length])

  useEffect(() => {
    if (isMobile && flattenedReport.length !== 0) {
      toggleForm(false)
    }
  }, [report, isMobile, flattenedReport.length, toggleForm])

  return (
    <ReportLayout
      reportName={"Cash Adjustments"}
      calendarInput={CalendarInputType.DateRange}
      locationInput={LocationInputType.SelectGetLoad}
      showHeaderDate={showHeaderDate}
      headerDateRange={headerDateRange}
      showForm={showForm}
      accordionHeadings={["Name", "Add", "Remove", "Stack"]}
      showAccordionHeadings={Boolean(flattenedReport?.length ?? 0)}
      toggleForm={toggleForm}
      handleLocationChange={handleLocationChange}
      selectedValue={selectedValue}
      handleSetSelectedValue={handleSetSelectedValue}
      setReportDateRange={setReportDateRange}
      openFiltersHandler={props.openFiltersHandler}
      location={location}
      getReportHandler={async () => {
        if (selectedValue.id === null) {
          // Create a new URLSearchParams object with the current search params
          const newSearchParams = new URLSearchParams(searchParams.toString())

          // Delete the parameters you want to clear
          newSearchParams.set("locationId", "")
          newSearchParams.set("startDate", reportDateRange[0]?.format("YYYY-MM-DD") ?? "")
          newSearchParams.set("endDate", reportDateRange[1]?.format("YYYY-MM-DD") ?? "")
          newSearchParams.delete("businessId")
          newSearchParams.delete("locationName")

          // Use the 'navigate' method with the 'replace' option
          navigate(`?${newSearchParams.toString()}`, { replace: true })
        } else if (selectedValue.id !== null && selectedValue.id !== undefined) {
          // Create a new URLSearchParams object with the current search params
          const newSearchParams = new URLSearchParams(searchParams.toString())

          // Set the search params as you had before
          newSearchParams.set("locationId", selectedValue.id)
          newSearchParams.set("businessId", selectedValue.businessId)
          newSearchParams.set("locationName", selectedValue.name)
          newSearchParams.set("startDate", reportDateRange[0]?.format("YYYY-MM-DD") ?? "")
          newSearchParams.set("endDate", reportDateRange[1]?.format("YYYY-MM-DD") ?? "")

          // Use the 'navigate' method with the 'replace' option
          navigate(`?${newSearchParams.toString()}`, { replace: true })
        }
        try {
          await getReportsHandler(
            fetchReport,
            setIsFetchingReport,
            handleSnackBar,
            setReport,
            location,
            hasHeaderDate,
            requiresAPICall,
            setShowHeaderDate,
            "data.data.businesses",
            {
              startDate: reportDateRange[0]
                ?.clone()
                .startOf("day")
                .add(4, "hour")
                .format("YYYY-MM-DD HH:mm:ss"),
              endDate: reportDateRange[1]
                ?.clone()
                .add(1, "day")
                .startOf("day")
                .add(3, "hour")
                .add(59, "minute")
                .add(59, "second")
                .add(999, "millisecond")
                .format("YYYY-MM-DD HH:mm:ss.SSS"),
              // locationIds: "",
              businessId: selectedValue.businessId,
              terminalIds: ["All Types"],
            },
            isMountedRef
          )
          setHeaderDateRange([reportDateRange[0], reportDateRange[1]])
          setFirstSelectedValue(selectedValue)
        } catch (error) {
          console.log(error)
        }
      }}
    >
      <>
        {isFetchingReport && <ReportSkeleton />}
        {!isFetchingReport && flattenedReport.length === 0 && snackbarOpen && (
          <Alert severity={snackbarSeverity} sx={{ width: "100%" }}>
            {snackbarMessage}
          </Alert>
        )}
        {!isFetchingReport && flattenedReport.length > 0 && <Report report={flattenedReport} />}
      </>
    </ReportLayout>
  )
}
