import { Typography } from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { Accordion, AccordionSummary, SummarySection, SummaryItem } from "../styles/AccordionStyles"
import { type OutputJson as OutputJsonRevenue } from "../middlewares/flattenRevenue"
import { type FlattenedSalesReport } from "../types/ISalesReportTypes"
import { formatNegative, formatNegativePercent } from "../utils/formatAmount"
import { getNetColor } from "../utils/getNetColor"

type CombinedOutputJson = OutputJsonRevenue | FlattenedSalesReport | null

interface SummaryCardProps {
  summaryData: CombinedOutputJson | null
  reportType: string
  accordionSummaries?: string[]
}

export const SummaryCard = ({ summaryData, reportType, accordionSummaries }: SummaryCardProps) => {
  const isRevenueReport = (data: CombinedOutputJson): data is OutputJsonRevenue => {
    return reportType === "Revenue"
  }

  let summaryPairs: Record<string, number | string | undefined> = {
    Total: "Total",
  }

  if (isRevenueReport(summaryData)) {
    summaryPairs = {
      ...summaryPairs,
      Sales: summaryData?.totalSummary.s,
      Redeem: summaryData?.totalSummary.r,
      Net: summaryData?.totalSummary.n,
    }
  } else {
    summaryPairs = {
      ...summaryPairs,
      Sales: summaryData?.totalSummary.s,
      Free: summaryData?.totalSummary.f,
      Promo: summaryData?.totalSummary.p,
      "%": summaryData?.totalSummary.percentage,
    }
  }

  return (
    <Accordion
      sx={{
        "&.MuiAccordion-root": {
          padding: 0,
          marginTop: "0px",
          marginBottom: "24px",
        },
        cursor: "default",
        pointerEvents: "none",
      }}
    >
      <AccordionSummary
        sx={{ backgroundColor: "#DEE2E6" }}
        expandIcon={<ExpandMoreIcon sx={{ visibility: "hidden" }} />}
      >
        <SummarySection sx={{ flexBasis: "100%" }}>
          {Object.entries(summaryPairs).map(([label, value], index) => (
            <SummaryItem
              key={index}
              sx={{
                ...(label === "%" && { display: { xs: "none", sm: "block" } }),
                ...(label === "Total"
                  ? {
                      textAlign: "left",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }
                  : { textAlign: "right" }),
                fontWeight: 600,
                minWidth: isRevenueReport(summaryData) ? "68px" : "56px",
                flexBasis: isRevenueReport(summaryData) ? "25%" : "20%",
              }}
            >
              <Typography variant="body1" component="div" color="textPrimary" fontWeight={600}>
                {label}
              </Typography>
              <Typography
                variant="body1"
                component="div"
                color={label === "Net" ? getNetColor(value as number) : "textPrimary"}
              >
                {label !== "Total" && (
                  <>
                    <span className="hidden sm:block">
                      {label === "%"
                        ? formatNegativePercent(value as number)
                        : formatNegative(value as number).full}
                    </span>
                    <span className="block sm:hidden">
                      {label === "%"
                        ? formatNegativePercent(value as number)
                        : formatNegative(value as number).short}
                    </span>
                  </>
                )}
              </Typography>
            </SummaryItem>
          ))}
        </SummarySection>
      </AccordionSummary>
    </Accordion>
  )
}
