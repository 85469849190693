import React from "react"

import { type OutputJson } from "../../../middlewares/flattenRevenue"
import { AccordionRevenueItem } from "./AccordionRevenueItem"
import { formatAmount, formatNegative } from "../../../utils/formatAmount"

interface Props {
  report: OutputJson
}

export function RevenueReport({ report }: Props) {
  return (
    <div>
      {Object.entries(report.stores).map(([businessId, { name, stores, totalSummary }], index) => {
        const collapseAllBusinesses = Object.entries(report.stores).length === 1
        const storeName = name
        const fullSales = formatAmount(totalSummary.s).full
        const shortSales = formatAmount(totalSummary.s).short
        const fullRedemption = formatAmount(totalSummary.r).full
        const shortRedemption = formatAmount(totalSummary.r).short
        const fullNet = formatNegative(totalSummary.n).full
        const shortNet = formatNegative(totalSummary.n).short

        return (
          <React.Fragment key={`${name}${index}`}>
            <AccordionRevenueItem
              collapseAllBusinesses={collapseAllBusinesses}
              businessId={businessId}
              name={storeName}
              stores={stores}
              fullSales={fullSales}
              shortSales={shortSales}
              fullRedemption={fullRedemption}
              shortRedemption={shortRedemption}
              fullNet={fullNet}
              shortNet={shortNet}
              totalSummary={totalSummary}
            />
          </React.Fragment>
        )
      })}
    </div>
  )
}
