import { useState, useEffect, useRef } from "react"
import { useGetOverviewMutation } from "../../redux/business/business.api"
import { ReportLayout } from "../../layouts/ReportLayout"
import { OverviewBusinesses } from "../../components/Overview/OverviewBusinesses"
import { GetOverviewSkeleton } from "../../layouts/Skeletons/GetOverviewSkeleton"
import { getReportsHandler } from "../../utils/ReportHandlerInput"
import { CalendarInputType } from "../../types/reportTypes"
import { PeriodSkeleton } from "../../layouts/Skeletons/PeriodSkeleton"
import { type Dayjs } from "dayjs"
import Alert, { type AlertColor } from "@mui/material/Alert"
import { type HandleSnackBarType, LocationInputType } from "../../types/reportTypes"
import type { OverviewData } from "../../components/Overview/OverviewTypesFlattened"
import { showDelayedLoadingToast } from "../../utils/showDelayedLoadingToast"
import toast from "react-hot-toast"

interface Props {
  openFiltersHandler?: () => void
}

export function Overview(props: Props) {
  const [fetchReport] = useGetOverviewMutation()
  const [location, setLocation] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState("")
  const [showHeaderDate, setShowHeaderDate] = useState(false)
  const hasHeaderDate = false
  const requiresAPICall = true
  const [, setReportDateRange] = useState<[Dayjs | null, Dayjs | null]>([null, null])
  const [headerDateRange] = useState<[Dayjs | null, Dayjs | null]>([null, null])
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>("error")

  const [report, setReport] = useState([])

  const isMountedRef = useRef(true)

  const [isFetchingReport, setIsFetchingReport] = useState(false)
  const allLocationsOption = { name: "All Locations", id: null }
  const [selectedValue, setSelectedValue] = useState(allLocationsOption)

  const handleLocationChange = (e: React.SyntheticEvent<Element, Event>, value: number | null) => {
    setLocation(value !== null ? value.toString() : null)
  }

  const handleSetSelectedValue = (value: any | null) => {
    setSelectedValue(value)
  }
  const handleSnackBar: HandleSnackBarType = (isOpen, severity, message) => {
    setSnackbarSeverity(severity)
    setSnackbarMessage(message)
    setSnackbarOpen(isOpen)
  }

  useEffect(() => {
    // When the component mounts
    isMountedRef.current = true

    return () => {
      // When the component unmounts
      isMountedRef.current = false
    }
  }, [])

  useEffect(() => {
    ;(async () => {
      const toastId = showDelayedLoadingToast(3000, isMountedRef)
      setIsLoading(true)
      setIsFetchingReport(true)
      try {
        const res: any = await fetchReport({ sortBy: "problematic" })
        setReport(res.data.data.overView)
      } finally {
        clearTimeout(toastId)
        if (isMountedRef.current) {
          toast.dismiss("loadingToast") // Dismiss the loading toast
        }
        setIsLoading(false)
        setIsFetchingReport(false)
      }
    })().catch(() => {
      setIsLoading(false)
      setIsFetchingReport(false)
    })
  }, [])

  if (isLoading) {
    return <GetOverviewSkeleton reportName={"Overview"} />
  }
  console.log("Overview Report: ", report)
  return (
    <ReportLayout
      reportName={"Overview"}
      calendarInput={CalendarInputType.NoDate}
      locationInput={LocationInputType.AutoLoad}
      showHeaderDate={showHeaderDate}
      headerDateRange={headerDateRange}
      showForm={false}
      accordionHeadings={[]}
      showAccordionHeadings={false}
      toggleForm={() => {}}
      handleLocationChange={handleLocationChange}
      selectedValue={selectedValue}
      handleSetSelectedValue={handleSetSelectedValue}
      setReportDateRange={setReportDateRange}
      openFiltersHandler={props.openFiltersHandler}
      location={location}
      isActiveReport={Boolean(report?.length ?? 0)}
      getReportHandler={async () => {
        try {
          if (location !== null) {
            await getReportsHandler(
              fetchReport,
              setIsFetchingReport,
              handleSnackBar,
              setReport,
              location,
              hasHeaderDate,
              requiresAPICall,
              setShowHeaderDate,
              "data.data.storeList",
              {},
              isMountedRef
            )
          } else {
            handleSnackBar(true, "error", "Please select a location first.")
          }
        } catch (error) {
          console.log("error", error)
        }
      }}
    >
      <>
        {isFetchingReport && <PeriodSkeleton />}
        {!isFetchingReport && report?.length === 0 && snackbarOpen && (
          <Alert severity={snackbarSeverity} sx={{ width: "100%" }}>
            {snackbarMessage}
          </Alert>
        )}
        {!isFetchingReport && report !== null && report.length !== 0 && (
          <OverviewBusinesses overView={report as unknown as OverviewData} />
        )}
      </>
    </ReportLayout>
  )
}
