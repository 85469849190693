import {
  CardHeader,
  CardContent,
  Card,
  Typography,
  useTheme,
  useMediaQuery,
  Tooltip,
  Grid,
  IconButton,
} from "@mui/material"
import ReactToPrint from "react-to-print"
import { formatDate } from "../../../utils/formatDate"
import { formatFillCode } from "../../../utils/formatFillCode"
import { ShareButton } from "../../ShareButton"
import PrintIcon from "@mui/icons-material/Print"
import type { FillCodeLookupCardProps } from "../../../types/IFillCodeLookup"
import machineIcon from "../../../assets/licensing/machine.svg"
import React, { useRef, useState } from "react"
import Print from "../Print"
import { CopyButton } from "../../CopyButton"
import { formatAmount } from "../../../utils/formatAmount"
import { type PrintDataTypes } from "../../../types/IPrint"
import { PurchaseTimeIcon } from "../PurchaseTimeIcon"

const FillCodeLookupCard = ({
  machineId,
  purchaseDateTime,
  fillCode,
  amount,
  fillCount,
}: FillCodeLookupCardProps) => {
  const theme = useTheme()
  const matchesXS = useMediaQuery(theme.breakpoints.down("sm"))
  const formattedFillCode = formatFillCode(fillCode)
  const printComponentRef = useRef<HTMLElement>(null)
  const [printData, setPrintData] = useState<PrintDataTypes>()

  const shareText = `Machine ${String(machineId)}\nPurchase Date: ${String(
    formatDate(purchaseDateTime)
  )}\nFill Code: ${String(formattedFillCode)}\nSequence ${String(fillCount)}\nAmount: ${String(
    formatAmount(amount).full
  )}`

  const prepareDataForPrint = (): PrintDataTypes => {
    const machineData = [
      {
        key: `machineId-${String(machineId)}`,
        label: "Machine ID",
        value: machineId,
      },
      {
        key: `purchaseDate-${String(machineId)}`,
        label: "Purchase Date",
        value: formatDate(purchaseDateTime),
      },
      {
        key: `fillCode-${String(machineId)}`,
        label: "Fill Code",
        value: formatFillCode(fillCode),
      },
      {
        key: `count-${String(machineId)}`,
        label: "Sequence",
        value: `${String(fillCount)}`,
      },
      {
        key: `amount-${String(machineId)}`,
        label: "Amount",
        value: formatAmount(amount).full,
      },
      {
        key: `divider`,
        label: "",
        value: "",
      },
    ]
    return [...machineData]
  }

  return (
    <Card
      sx={{
        borderRadius: 0,
        borderTop: "1px solid #a9a9a9",
      }}
      variant="outlined"
    >
      <CardHeader
        sx={{
          padding: matchesXS ? 1 : 2,
          backgroundColor: "#DEE2E6",
          "& .MuiCardHeader-action": {
            margin: "1px",
          },
        }}
        title={
          <div style={{ display: "flex" }}>
            <img className="w-4 h-7 mr-1" alt="machine icon" src={machineIcon} />
            {machineId}
          </div>
        }
        subheader={
          <Typography
            variant="body2"
            sx={{
              display: "flex",
              alignContent: "center",
              alignItems: "center",
            }}
            color="textSecondary"
          >
            <PurchaseTimeIcon />
            {formatDate(purchaseDateTime)}
          </Typography>
        }
        action={
          <div
            style={{
              display: "flex",
              minHeight: "50px",
              flexDirection: matchesXS ? "column-reverse" : "row",
              justifyContent: matchesXS ? "space-evenly" : "flex-end",
              alignItems: matchesXS ? "flex-end" : "baseline",
            }}
          >
            <ReactToPrint
              trigger={() => (
                <Tooltip placement="left" title="Print">
                  <IconButton>
                    <PrintIcon sx={{ fontSize: 20, color: "black" }} />
                  </IconButton>
                </Tooltip>
              )}
              content={() => printComponentRef.current}
              removeAfterPrint={false}
              onBeforeGetContent={async () => {
                const preparedData = prepareDataForPrint()
                setPrintData(preparedData)
              }}
            />

            <ShareButton
              fontSize="20px"
              shareText={shareText}
              title="vgtCentral Fill Code Lookup!"
            />
          </div>
        }
      ></CardHeader>
      <CardContent sx={{ padding: 0 }}>
        <Grid
          sx={{
            backgroundColor: "#F8F9FA",
            paddingY: 1,
            paddingX: matchesXS ? 1 : 2,
            display: "grid",
          }}
          container
          gridTemplateColumns={"5fr 2fr 3fr 1fr"}
          columnSpacing={1}
        >
          <Grid item>
            <Typography sx={{ display: "flex", alignItems: "center" }} variant="subtitle2">
              Fill Code{" "}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle2" align="right">
              Seq.
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle2" align="right">
              Amount
            </Typography>
          </Grid>
          <Grid item></Grid>
        </Grid>

        <Grid
          container
          gridTemplateColumns={"5fr 2fr 3fr 1fr"}
          columnSpacing={1}
          sx={{
            paddingY: 1,
            paddingX: matchesXS ? 1 : 2,
            display: "grid",
            alignItems: "baseline",
          }}
        >
          <Grid item>
            <Typography
              sx={{
                letterSpacing: "1px",
              }}
              variant="body2"
            >
              {formattedFillCode}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" align="right">
              {fillCount}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" align="right">
              {formatAmount(amount).full}
            </Typography>
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              paddingRight: matchesXS ? 0 : 1,
            }}
          >
            <CopyButton copyText={fillCode} />
          </Grid>
        </Grid>
      </CardContent>

      <div style={{ display: "none" }}>
        {printData !== undefined && <Print ref={printComponentRef} printData={printData} />}
      </div>
    </Card>
  )
}
export default React.memo(FillCodeLookupCard)
