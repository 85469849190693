import React from "react"

import { useDispatch, useSelector } from "react-redux"
import { type RootState } from "../../../redux/store"
import { AccordionCollectionsItem } from "./AccordionCollectionsItem"
import { Alert } from "@mui/material"
import { hideHeader } from "../../../redux/filters/hideHeader.slice"

interface Terminal {
  terminalName: string
  totalTerminalAdd: number
  totalTerminalRemove: number
  totalTerminalStack: number
  totalTerminalBill: number
  transactions: any[]
}

interface Location {
  locationId: string
  totalLocationAdd: number
  totalLocationRemove: number
  totalLocationStack: number
  totalLocationBill: number
  productType: any
  terminals: Terminal[]
}

interface Business {
  businessId: string
  businessName: string
  totalBusinessAdd: number
  totalBusinessRemove: number
  totalBusinessStack: number
  totalBusinessBill: number
  locations: Location[]
}

interface Props {
  report: Business[]
}

export function Report({ report }: Props) {
  const collapseAllBusinesses = report.length === 1
  const productFilters = useSelector((state: RootState) => state.filters.productFilters)
  const transactionFilters = useSelector((state: RootState) => state.filters.transactionFilters)
  const dispatch = useDispatch()

  const filterTransactions = (transactions: any) => {
    return transactions.filter((transaction: any) => {
      const showAllTransactions = Object.keys(transactionFilters).every(
        (filterName) => !transactionFilters[filterName]
      )

      if (showAllTransactions) return true

      const showTransactions = Object.keys(transactionFilters).some((filterName) => {
        if (transaction.type === filterName && transactionFilters[filterName]) return true
        else return false
      })

      return showTransactions
    })
  }

  const filterBusinesses = (businesses: Business[]): Business[] => {
    const noFiltersSelected = Object.values(productFilters).every((value) => !value)

    if (noFiltersSelected) {
      return businesses
    }

    return businesses.map((business: Business) => ({
      ...business,
      locations: business.locations.filter((location: Location) => {
        const filterValue = productFilters[location.productType]
        if (typeof filterValue === "boolean") {
          return filterValue
        }
        return true
      }),
    }))
  }

  const filteredBusinesses = filterBusinesses(report)
  const hasBusinesses = filteredBusinesses.length > 0

  const hasTransactions = report.some((business) =>
    business.locations.some((location) =>
      location.terminals.some((terminal) => terminal.transactions.length > 0)
    )
  )

  const dontShowBusinesses = filteredBusinesses.every((business) => {
    return business.locations.every((location) => {
      return location.terminals.every((terminal) => {
        const filteredTransactions = filterTransactions(terminal.transactions)
        return filteredTransactions.length === 0
      })
    })
  })
  dispatch(hideHeader(!dontShowBusinesses))
  if (!hasBusinesses || !hasTransactions || dontShowBusinesses) {
    return (
      <Alert severity="error" sx={{ width: "100%" }}>
        No data to show with the current selection.
      </Alert>
    )
  }

  return (
    <div className="shadow-lg">
      {filterBusinesses(report).map(
        (
          {
            businessId,
            businessName,
            totalBusinessAdd,
            totalBusinessRemove,
            totalBusinessStack,
            totalBusinessBill,
            locations,
          },
          index
        ) => {
          return (
            <React.Fragment key={index}>
              <AccordionCollectionsItem
                collapseAllBusinesses={collapseAllBusinesses}
                businessId={businessId}
                businessName={businessName}
                totalBusinessAdd={totalBusinessAdd}
                totalBusinessRemove={totalBusinessRemove}
                totalBusinessStack={totalBusinessStack}
                totalBusinessBill={totalBusinessBill}
                locations={locations}
              />
            </React.Fragment>
          )
        }
      )}
    </div>
  )
}
